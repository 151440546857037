/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import i18next from "i18next";


/**
 * This component represents the footer section of the application.
 * It displays the copyright information and the current year.
 *  
 * @author hatem sfar
 *
 */
function Footer() {
    const date = new Date();
    return (
        <>
            <footer style={{
                background: "rgb(140, 140, 240)",
                textAlign: "center",
                //position: "absolute",
                bottom: "0",
                left: "0",
                right: "0",
                zIndex: "9999"
            }}>
                <font size="3" color="white" >{i18next.t('Copyright')} © OKIT - {date.getFullYear()} </font>


            </footer>
        </>
    );
}

export default Footer;