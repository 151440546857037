/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { iconAlertGood, iconAlertWarning, iconAlertError, iconAlertNoAxle, iconAlertDisabled } from '../../utils/iconAlert.js';
import { iconCrosshair, iconCrosshairDisabled } from '../../utils/iconStandard.js';
import i18next from 'i18next';
import { useNavigate } from "react-router-dom";
import useWindowSize from '../../utils/useWindowSize.js';
import "../../assets/css/card.css";




/**
 * This component represents the the Card 
 * for the device info
 * 
 * @author hatem sfar
 *
 */
const CardList = ({ cards, isLoading, onData, hoveredCoordinate, deviceCoordinate, closeSideBar }) => {
    const navigate = useNavigate();
    const windowSize = useWindowSize();


    /**
     * 
     */
    useEffect(() => {
        // Start the loading animation
    }, [cards, isLoading]);

    /**
     * button to go to DeviceDetials component
     * @param {*} id 
     * @returns 
     */
    const goToDetails = (id) => {
        return navigate(`/devices/detail/${id}`);
    }
    /**
     * to send info to parent component
     * @param {*} data 
     */
    const sendDataToParent = (data) => {
        windowSize[0] < 991 && closeSideBar()
        // Pass the data to the parent component
        onData(data);
    };

    return (
        <>
            <Container fluid="md">
                {cards && cards.map((card, i) => (
                    <div
                        key={i}
                        className="k-listview-item row p-2 border-bottom align-middle"
                        style={{
                            margin: 0,
                            backgroundColor: hoveredCoordinate?.device === card.id && "lightblue",
                            width: windowSize[0] > 991 ? "290px" : "100%"
                        }}
                        ref={ref => {
                            if (
                                hoveredCoordinate?.device === card.id &&
                                ref &&
                                ref.scrollIntoView
                            ) {
                                ref.scrollIntoView({ behavior: "smooth", block: "end" });
                            }
                        }}
                    >

                        <div
                            className="col-1"
                            style={{
                                background:
                                    !card?.active
                                        ? "gray"
                                        : card?.alert === "error"
                                            ? "#d32f2f"
                                            : card?.alert === "warning"
                                                ? "rgb(205 148 39)"
                                                : card?.alert === "good"
                                                    ? "#8c8cf0"
                                                    : "#8c8cf0",
                                borderBottomLeftRadius: "10px",
                                borderTopLeftRadius: "10px",
                                width: "20px"
                            }} >
                        </div>


                        <div
                            className="col-7"
                            style={{ cursor: "pointer" /*, width: "49.666667%"*/ }}
                            onClick={() => goToDetails(card.id)}>
                            <h2
                                style={{
                                    fontSize: 14,
                                    color: "#454545",
                                    marginBottom: 0,
                                }}
                                className="text-uppercase"
                            >
                                {card.name}
                            </h2>
                            <div
                                style={{
                                    fontSize: 12,
                                    color: "#a0a0a0",
                                }}
                            >
                                {card.description}
                            </div>
                        </div>

                        <div
                            style={{
                                padding: "initial",
                                alignSelf: "center",
                                textAlignLast: "center",

                            }}
                            className="col-1">
                            <div
                                className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base">
                                {!card?.active
                                    ? <img
                                        src={iconAlertDisabled}
                                        height="20"
                                        className="d-inline-block"
                                        alt="disable signe"
                                    />
                                    : card?.alert === "error" ?

                                        <img
                                            src={iconAlertError}
                                            height="20"
                                            className="d-inline-block"
                                            alt="stop signe"
                                        />

                                        : card?.alert === "noaxle" ?

                                            <>
                                                <img
                                                    src={iconAlertNoAxle}
                                                    height="20"
                                                    className="d-inline-block"
                                                    alt="warning signe"
                                                />
                                            </>
                                            :
                                            card?.alert === "warning" ?

                                                <img
                                                    src={iconAlertWarning}
                                                    height="20"
                                                    className="d-inline-block"
                                                    alt="warning signe"
                                                />

                                                :
                                                <>
                                                    <img
                                                        src={iconAlertGood}
                                                        height="20"
                                                        className="d-inline-block"
                                                        alt="greenLED signe"
                                                    />
                                                </>
                                }
                            </div>
                        </div>

                        <div
                            style={{
                                cursor: "pointer",
                                padding: "initial",
                                alignSelf: "center",
                                textAlignLast: "center"
                            }}
                            className="col-2"
                            onClick={() => {
                                if (deviceCoordinate?.find((device) => device.id === card.id && device.coordinate !== null)) {
                                    sendDataToParent(card);
                                }
                            }}
                        >
                            <div
                                className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base">
                                <img
                                    src={deviceCoordinate?.find((device) => device.id === card.id && device.coordinate !== null)
                                        ? iconCrosshair
                                        : iconCrosshairDisabled}
                                    height="20"
                                    className="d-inline-block"
                                    alt="crosshair signe"
                                    style={{
                                        cursor: deviceCoordinate?.find((device) => device.id === card.id && device.coordinate !== null)
                                            ? "pointer"
                                            : "default"
                                    }}
                                />
                            </div>
                        </div>


                    </div>
                ))}



                {
                    !isLoading && Array.isArray(cards) && cards.length === 0 ?
                        (
                            !isLoading ? (
                                <>
                                    <div style={{ marginTop: "20px" }} className="d-flex align-items-center justify-content-center">
                                        <div className="text-center">
                                            <font size="3"> <span className="text-danger"></span>{i18next.t('noDataFound')} </font>

                                        </div>
                                    </div>
                                </>
                            ) : null
                        ) : null
                }

            </Container >
        </>
    );
};

export default CardList;