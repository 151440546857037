/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import axios from "axios";
import apiList from "../utils/apiClient.js";

/**
 * This component implements the api's call
 *
 * @author hatem sfar
 *
 */
const getDeviceApi = async () => {
  const { data } = await axios.get(apiList.DeviceListClient);
  return data;
};
const getDeviceByIdApi = async (id) => {
  const { data } = await axios.get(apiList.DeviceDetail + id);
  return data;
};
const getDeviceByAlertApi = async (id, data2) => {
  const { data } = await axios.get(apiList.DeviceAlert + id, {
    params: {
      data: data2,
    },
  });
  return data;
};
const updateDeviceApi = async (device, active) => {
  const { data } = await axios.get(apiList.DeviceUpdate, {
    params: {
      data: device,
      active: active,
    },
  });
  return data;
};

const sendSMSTestDeviceApi = async (MobileNumber, messageSMS, tag) => {
  const { data } = await axios.get(apiList.DeviceSendSMSTest, {
    params: {
      MobileNumber: MobileNumber,
      messageSMS: messageSMS,
      tag: tag,
    },
  });
  return data;
};
const sendMAILTestDeviceApi = async (
  email,
  fromMail,
  subjectMail,
  messageMail
) => {
  const { data } = await axios.get(apiList.DeviceSendMailTest, {
    params: {
      email: email,
      fromMail: fromMail,
      subjectMail: subjectMail,
      messageMail: messageMail,
    },
  });
  return data;
};

const checkServerHealthApi = async () => {
  const { data } = await axios.get(apiList.CheckHealthServer);
  return data;
};
export {
  getDeviceApi,
  getDeviceByIdApi,
  getDeviceByAlertApi,
  updateDeviceApi,
  sendSMSTestDeviceApi,
  sendMAILTestDeviceApi,
  checkServerHealthApi,
};
