/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React from 'react';
import reload from "../../assets/images/reload.svg";

/**
 * This component represents the Relaod button
 * 
 * @author hatem sfar
 *
 */
const ReloadButton = ({ getCoordinate }) => {
    const onClick = () => {
        getCoordinate();
    };

    return (
        <div className='reload_btn'>
            <img
                src={reload}
                onClick={onClick}
                height="20"
                className="d-inline-block"
                alt="crosshair"
            />
        </div>
    );
};

export default ReloadButton;