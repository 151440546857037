const HEARTBEAT_INTERVAL = 20000; // 20 seconds
const MAX_RETRIES = 10;


class WebSocketManager {
    constructor(url) {
        this.url = url;
        //this.heartbeatInterval = null;
        this.retryCount = 0;
        this.onMessageCallbacks = [];
        this.pingInterval = null;
        this.setupKeepAlive();
        this.connect();
    }


    setupKeepAlive() {
        this.pingInterval = setInterval(() => {
            if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
                this.webSocket.send("ping");
            } else {
                this.reconnect();
            }
        }, HEARTBEAT_INTERVAL);
    }

    connect() {
        if (this.retryCount >= MAX_RETRIES) {
            console.log("WebSocket connection failed after maximum retries.");
            return;
        }

        if (!this.webSocket || this.webSocket.readyState !== WebSocket.OPEN) {
            this.webSocket = new WebSocket(this.url);

            console.log(`WebSocket client started.`);

            this.webSocket.onopen = () => {
                //this.startHeartbeat();
                this.setupKeepAlive();
            };

            this.webSocket.onmessage = (event) => {
                this.handleWebSocketMessage(event);
            };
            /*
            this.webSocket.onclose = () => {
                this.stopHeartbeat();
                this.webSocket = null;
                // Reconnect here if needed, or handle reconnection elsewhere
            };
            */
        }
    }

    reconnect() {
        if (!this.webSocket || this.webSocket.readyState !== WebSocket.OPEN) {
            this.connect();
        }
    }

    /*
        stopKeepAlive() {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        }*/

    /*
        startHeartbeat() {
            this.heartbeatInterval = setInterval(() => {
                if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
                    this.webSocket.send("ping");
                }
            }, HEARTBEAT_INTERVAL);
        }
    
        stopHeartbeat() {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
    
    close() {
        if (this.webSocket) {
            this.webSocket.close();
            this.stopHeartbeat();
            this.webSocket = null;
        }
    }
    */

    setOnMessageCallback(callback) {
        this.onMessageCallbacks.push(callback);
    }

    removeOnMessageCallback(callback) {
        this.onMessageCallbacks = this.onMessageCallbacks.filter(
            (cb) => cb !== callback
        );
    }

    handleWebSocketMessage(event) {
        if (event.data === "pong") {
            return; // Ignore heartbeat responses
        }
        this.onMessageCallbacks.forEach((callback) => {
            callback(event);
        });
    }
}

export default WebSocketManager;
