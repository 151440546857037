/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React from 'react'
import i18next from 'i18next';

/**
 * This component implements notFound interface
 * 
 * @author hatem sfar
 *
 */
const NotFound = (props) => {
    return (
        <>
            <br /><br /><br />
            <div className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3"> <span className="text-danger">Opps!</span>{i18next.t('notfound')} </p>
                    <p className="lead">
                        {i18next.t('notexist')}
                    </p>
                    <a href="/devices" className="btn btn-primary">{i18next.t('GoHome')}</a>
                </div>
            </div>
        </>
    );
};
export default NotFound;