/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React from 'react';
import Speedometer, {
    Background,
    Arc,
    Needle,
    Progress,
    Marks,
    Indicator,
    DangerPath
} from 'react-speedometer';
import "../../assets/css/speedometer.css";



/**
 * This component represents reset button to resset the screen zoom
 * 
 * @author hatem sfar
 *
 */
const SpeedometerView = ({ speed, maxSpeedDevice }) => {


    const reAreaSpeed = 20
    const scaleLimit = parseInt(maxSpeedDevice) + reAreaSpeed;
    let fullAngle = 250 // is the 100% of the speedometer
    const dgreePerKmh = fullAngle / scaleLimit
    const greeRedArea = dgreePerKmh * reAreaSpeed



    return (
        <div className='speed_border'>
            <Speedometer
                value={speed}
                max={scaleLimit}
                fontFamily='helvetica'
                width={200}
                height={200}
            >
                <Background />
                <Arc />
                <Needle
                    circleColor='#8c8cf0'
                />

                <DangerPath angle={greeRedArea} />


                <Progress color='#8c8cf0' />
                <Marks fontSize={13} />
                <Indicator>
                    {(value, textProps) => (
                        <text
                            {...textProps}
                            fontSize={20}
                            fill="aliceblue"
                            x={200 / 2}
                            y={180}
                            textAnchor="middle"
                            fontFamily='helvetica'
                            className="text-uppercase"
                        >
                            {value} km/h
                        </text>
                    )}
                </Indicator>
            </Speedometer>
        </div>
    );
};

export default SpeedometerView;

