/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useState } from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import i18next from "i18next";


/**
 * This component represents the the SearchBar 
 * for the devices
 * 
 * @author hatem sfar
 *
 */
const SearchBar = ({ cards, setFilteredCards }) => {
  const [query, setQuery] = useState('');

  /**
   * to handle the inputed changes
   * @param {*} event 
   */
  const handleChange = event => {
    const { value } = event.target;
    setQuery(value);
    if (cards) {
      const filteredCards = cards.filter(card =>
        card.name.toLowerCase().includes(value.toLowerCase()) || card.description.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCards(filteredCards);
    }
  };

  return (
    <>

      <div style={{ textAlign: "-moz-center" }}>
        <div style={{ textAlign: "-webkit-center" }}>
          <div style={{
            //height: "40px",
            //marginTop: "30px"
          }} className="p-1 bg-light rounded rounded-pill shadow-sm">
            <div className="input-group">
              <input name="searsh" type="search" placeholder={i18next.t('searching')}
                aria-describedby="button-addon1" className="form-control border-0 bg-light" value={query} onChange={handleChange} />
              <div className="input-group-append">
                <button id="button-addon1" type="submit" className="btn btn-link text-primary"><i ><AiOutlineSearch /></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;