/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import LangDropDown from "../translation/LangDropDown";
import i18next from "i18next";
import ClientPrefixDropDown from '../ClientPrefixDropDown';
import auth from "../../auth/auth";
import ROLES from "../../utils/roles";
import useWindowSize from "../../utils/useWindowSize";
import ClientUserDropDown from "../ClientUserDropDown";



/**
 * The top navigation bar component for desktop.
 * 
 * @author hatem sfar
 *
 */
function NavBarMobile() {
    const windowSize = useWindowSize();

    return (
        <>
            <div
                id="navbar_mobile"
                style={{ flexDirection: windowSize[0] < 500 && "column-reverse" }}
            >

                <ul className="navbar-list">
                    <br />


                    <li>
                        {auth([ROLES.OPERATOR]) ? <ClientPrefixDropDown /> : <ClientUserDropDown />}
                        <hr className="navbar-line" />
                    </li>
                    <li>
                        <a
                            href="/devices"
                            className='navbar_a'
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor = "#5c636a";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.backgroundColor = "transparent";
                            }}
                        >
                            {i18next.t("Map")}
                        </a>
                        <hr className="navbar-line" />
                    </li>
                    <li>
                        {auth([ROLES.ADMIN, ROLES.OPERATOR]) && (
                            <a
                                href="/measurement"
                                className='navbar_a'
                                onMouseOver={(e) => {
                                    e.target.style.backgroundColor = "#5c636a";
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.backgroundColor = "transparent";
                                }}
                            >
                                {i18next.t("WebMapApi")}
                            </a>
                        )}
                        <hr className="navbar-line" />
                    </li>
                    <li>
                        <a
                            href="/user/settings"
                            className='navbar_a'
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor = "#5c636a";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.backgroundColor = "transparent";
                            }}
                        >
                            {i18next.t("Settings")}
                        </a>
                        <hr className="navbar-line" />
                    </li>
                    <li>
                        <LangDropDown />
                        <hr className="navbar-line" />
                    </li>
                </ul>


            </div>



            <div style={{ textAlign: "center" }} >
                <a
                    href="/logout"
                    className='navbar_a'
                    onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#5c636a";
                    }}
                    onMouseOut={(e) => {
                        e.target.style.backgroundColor = "transparent";
                    }}
                >
                    {i18next.t("Logout")}
                </a>
            </div>


        </>
    );
}

export default NavBarMobile;