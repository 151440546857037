/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
  */

import { useEffect } from "react";
import { getCoordinatesIntervallPaginationApi } from "../../hooks/coordinatesApi";
import { convertDateToISOWithOffset } from "./FctAnalyticMap";
import L from 'leaflet';
import TimeOutToast from '../../utils/TimeOutToast';



const delay = ms => new Promise(res => setTimeout(res, ms));




/**
 * 
 * @param {*} mapRef 
 * @param {*} setZoomLevel 
 * @returns 
 */
const MapEvents = ({ mapRef, setZoomLevel, setBoundsMap }) => {
    const map = mapRef.current;

    useEffect(() => {
        // Check if mapRef is initialized
        if (!map) return;

        const handleZoomEnd = () => {
            // Update the zoom level when the map is zoomed
            setZoomLevel(map.getZoom());
        };
        const handleMoveEnd = () => {
            // Update the bounds when the map is moved
            //console.log("map.getBounds(): ", map.getBounds())
            setBoundsMap(map.getBounds());
        };
        // Add event listener
        map.addEventListener('zoomend', handleZoomEnd);
        map.addEventListener('moveend', handleMoveEnd);

        // Remove event listener on component unmount
        return () => {
            map.removeEventListener('zoomend', handleZoomEnd);
            map.removeEventListener('moveend', handleMoveEnd);
        };
        //eslint-disable-next-line
    }, [map]);

    return null;
};


/**
 * 
 * @param {*} boundsMap 
 * @param {*} selectedDevice 
 * @param {*} dateStart 
 * @param {*} dateEnd 
 * @param {*} speedLimite 
 * @param {*} LIMITE 
 * @param {*} mapRef 
 * @param {*} polylineGroup_2 
 */
const loadMoreData = async (boundsMap, selectedDevice, dateStart, dateEnd, speedLimite, LIMITE, mapRef, polylineGroup_2, drawnCoordinates,setIsPolylineDone,setIsLoading) => {
    // declare vaiables 
    let isoDateStart = '';
    let isoDateEnd = '';
    let pageNumber = 1;
    let nextPage = true;

    // get teh bounds area
    var lat1 = boundsMap?._northEast?.lat;
    var lng1 = boundsMap?._northEast?.lng;
    var lat2 = boundsMap?._southWest?.lat;
    var lng2 = boundsMap?._southWest?.lng;

    let coordinateData = []

    // convert the date to UTC
    isoDateStart = convertDateToISOWithOffset(dateStart);
    if (dateEnd) {
        isoDateEnd = convertDateToISOWithOffset(dateEnd);
    } else {
        isoDateEnd = null;
    }

    while (nextPage) {
        let response;
        try {
            setIsLoading(true)
            await delay(1000);
            response = await getCoordinatesIntervallPaginationApi(selectedDevice.value, isoDateStart,
                isoDateEnd, pageNumber, lat1, lng1, lat2, lng2);

            coordinateData = response.result.coordinates //coordinateData.concat(response.result.coordinates);
            addPolylines(coordinateData, speedLimite, LIMITE, polylineGroup_2, mapRef, drawnCoordinates,response.result.nextPage,setIsPolylineDone);


            if (!response.result.nextPage) {
                nextPage = false;
                setIsLoading(false)
            }
            // Add the group of polylines and markers to the map
            polylineGroup_2.addTo(mapRef.current);

            pageNumber++;

        } catch (err) {
            if (err.response && err.response.status === 504) {
                console.error("Gateway Time-out: The server took too long to respond.");
                TimeOutToast()
            }
            // console.error("Error fetching data:", err);
            //setIsLoading(false)
            if (err?.response?.status === 404) {
                nextPage = false;
            }
        }
    }
}

/**
 * 
 * @param {*} coordinate 
 * @param {*} bounds 
 * @returns 
 */
const isCoordinateInBounds = (coordinate, bounds) => {
    if (!bounds) {
        return false;
    }

    return bounds.contains(coordinate);
};

/**
 * 
 * @param {*} coordinatesByDevice 
 * @param {*} speedLimite 
 * @param {*} LIMITE 
 * @param {*} polylineGroup_2 
 */
const addPolylines = async (coordinatesByDevice, speedLimite, LIMITE, polylineGroup_2, mapRef, drawnCoordinates,nextPage,setIsPolylineDone) => {

    // remove the old polylines in the specific area
    if (mapRef.current) {
        mapRef.current.eachLayer((layer) => {
            if (layer instanceof L.Polyline && layer.options.isPolyline) {
                const polylineCoords = layer.getLatLngs();

                // Check if polylineCoords is defined and has at least one element
                if (polylineCoords && polylineCoords.length > 0) {
                    // Check if at least one point of the polyline is within the current map bounds
                    if (polylineCoords.some((coord) => isCoordinateInBounds(coord, mapRef.current.getBounds()))) {
                        mapRef.current.removeLayer(layer);
                    }
                }
            }
        });
    }
    // Options for the polyline
    const polylineOptions = {
        weight: 4,
        opacity: 0.5,
    };

    // Function to add a polyline with a delay
    const addPolylineWithDelay = async (currentCoord, nextCoord) => {


        const timeDifference = Math.abs(new Date(nextCoord.measurementdate) - new Date(currentCoord.measurementdate));

        // Generate a unique identifier for the current coordinate
        const currentCoordIdentifier = `${currentCoord.latitude}-${currentCoord.longitude}-${nextCoord.latitude}-${nextCoord.longitude}`;
        // console.log("currentCoordIdentifier: ", currentCoordIdentifier)

        // Check if the coordinate has already been drawn
        if (!drawnCoordinates.has(currentCoordIdentifier) && timeDifference <= 20000) {

            //console.log("drow ...")
            // Create a polyline segment for this pair of coordinates with corresponding color
            const segmentLatLngs = [
                [currentCoord.latitude, currentCoord.longitude],
                [nextCoord.latitude, nextCoord.longitude],
            ];

            const speed = currentCoord.speed;
            const color = getSpeedColor(speed, speedLimite.value, LIMITE);
            polylineOptions.color = color;

            const polylineD = L.polyline(segmentLatLngs, polylineOptions);
            polylineD.bindPopup(`Device: ${currentCoord.deviceName}`);

            polylineGroup_2.addLayer(polylineD);

            // Add the current coordinate identifier to the set
            drawnCoordinates.add(currentCoordIdentifier);
        } else {
            //console.log("we have been here ...")
        }
        // Wait for 3 seconds before adding the next polyline
        await new Promise(resolve => setTimeout(resolve, 50));
    };

    // Iterate through coordinatesByDevice to set color based on speed
    for (let i = 0; i < coordinatesByDevice.length - 1; i++) {
        const currentCoord = coordinatesByDevice[i];
        const nextCoord = coordinatesByDevice[i + 1];

        // Add a polyline with delay only if the time difference is <= 1 second
        await addPolylineWithDelay(currentCoord, nextCoord);
    }

    if(!nextPage){
        setIsPolylineDone(true)
    }
}

/**
 * 
 * @param {*} speed 
 * @param {*} limit 
 * @param {*} tolerance 
 * @returns 
 */
const getSpeedColor = (speed, limit, tolerance) => {

    if (isNaN(speed)) {
        return "black";
    }
    else if (!isNaN(speed) && speed > limit) {
        return "green";
    } else if (!isNaN(speed) && speed < (limit - tolerance)) {
        return "red";
    } else if (!isNaN(speed) && (limit - tolerance) <= speed <= limit) {
        return "blue";
    }
};

export {
    MapEvents,
    loadMoreData
};