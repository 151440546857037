import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";
import Properties from "../pages/devcieDetailView/Properties";
import OverView from "../pages/devcieDetailView/OverView";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import DriverApp from "../pages/devcieDetailView/DriverApp";
import TireWeight from "../pages/devcieDetailView/TireWeight";

function SideBarTires({ children, device }) {
  const { collapseSidebar } = useProSidebar();
  const [selectedComponent, setSelectedComponent] = useState(
    children[0]?.component || null
  );
  const windowSize = useWindowSize();
  //eslint-disable-next-line
  const [isMobile, setIsMobile] = useState(windowSize[0] < 992 ? true : false);
  const navigate = useNavigate();

  /**
   *
   */
  useEffect(() => {
    isMobile && collapseSidebar();
    //eslint-disable-next-line
  }, [isMobile]);

  /**
   *
   * @param {*} component
   */
  const handleMenuItemClick = (component) => {
    //collapseSidebar(); // Collapse sidebar after clicking a menu item
    setSelectedComponent(component);
  };

  const BackToMap = () => {
    navigate("/devices", { replace: true });
  };

  /**
   *
   * @returns
   */
  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "OverView":
        return <OverView device={device} />;
      case "TireWeight":
        return <TireWeight device={device} />;
      case "Properties":
        return <Properties device={device} />;
      case "DriverApp":
        return <DriverApp device={device} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        id="app"
        className="mainWidthTires"
        style={{
          height: "100vh",
          display: "flex",
          position: "fixed",
          width: isMobile ? "100%" : "83%",
        }}
      >
        <Sidebar style={{ height: "100vh" }}>
          <Menu>
            <MenuItem
              icon={<MenuOutlinedIcon />}
              onClick={() => {
                collapseSidebar();
              }}
              style={{ textAlign: "center" }}
            >
              {device?.name}
            </MenuItem>
            {children.map((child, index) => (
              <MenuItem
                key={index}
                icon={child.icon}
                onClick={() => handleMenuItemClick(child.component)}
              >
                {child.name}
              </MenuItem>
            ))}
            <div style={{ flex: "1" }} />{" "}
            {/* This empty div will push the "Map" MenuItem to the bottom */}
            <MenuItem
              icon={<ArrowCircleLeftOutlinedIcon />}
              onClick={() => {
                BackToMap();
              }}
            >
              {"Map "}
            </MenuItem>
          </Menu>
        </Sidebar>
        {/* <main style={{ width: "-webkit-fill-available", overflowY: "scroll", WebkitOverflowScrolling: "touch", scrollbarWidth: "none", msOverflowStyle: "none" }}> */}
        <main
          style={{
            position: "relative",
            width: "100%",
            overflowY: "scroll",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {renderSelectedComponent()}
        </main>
      </div>
    </>
  );
}

export default SideBarTires;
