/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */

/**
 * This component represents the roles of the vcm app.
 * 
 * @author hatem sfar
 *
 */
const ROLES = Object.freeze({
    ADMIN: "admin",
    OPERATOR: "operator",
    USER: "user"
})
module.exports = ROLES