
import React from "react"
import i18next from 'i18next';


export function Loading({pos}) {
    return (
        <>
            <div style={{zIndex: 1000, position: pos}} className="loading">
                <div className="loader"></div>
                <div className="text">{i18next.t('Loading')}...</div>

            </div>
        </>
    )
}