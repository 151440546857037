/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import radar from "../../assets/images/radar.svg";
import radar_active from "../../assets/images/radar_active.svg";
import "../../assets/css/radarMap.css";
import L from "leaflet";
import { useWebSocket } from "../../components/webSocket/WebSocketContext";
import { toast } from "react-toastify";
import i18next from "i18next";
import useWindowSize from "../../utils/useWindowSize";
import { getMeasurementRangeApi } from "../../hooks/measurementApi";
import {
  water_1Pin,
  water_3Pin,
  defaultPin,
  dryIcon
} from "../../utils/iconPin";
import TimeOutToast from "../../utils/TimeOutToast";

/**
 * This component represents RadarMap
 *
 * @author hatem sfar
 *
 */
const RadarMap = ({
  deviceCoordinate,
  mapRef,
  coordinates,
  isRadarActive,
  setIsRadarActive,
  shadowMarkerRefs,
}) => {
  const isRadarActiveRef = useRef(isRadarActive); // Create a ref to hold the latest value
  const RADIUS_CIRCLE = 200;
  const RADIUS_CIRCLE_OUT = 300;

  const webSocketManager = useWebSocket();
  // const delay = ms => new Promise(res => setTimeout(res, ms));
  const windowSize = useWindowSize();
  // Initialize the deviceDataDictionary
  const markerGroup = L.featureGroup();
  const deviceDataDictionary = useRef({}); // Use ref to persist data across renders
  const [filteredDeviceIds, setFilteredDeviceIds] = useState();

  /**
   *
   */
  useEffect(() => {
    isRadarActive && startDetector();
    !isRadarActive && removeAllCircles();

    if (isRadarActive) {
      const filtered = deviceCoordinate?.filter((device) => device.active);
      const filteredIds = filtered && filtered.map((device) => device.id);
      setFilteredDeviceIds(filteredIds);
    }
    //eslint-disable-next-line
  }, [isRadarActive]);

  /**
   *
   */
  useEffect(() => {
    isRadarActiveRef.current = isRadarActive;
    //eslint-disable-next-line
  }, [isRadarActive]);

  /**
   *
   */
  const handleWebSocketMessage = useCallback(
    async (event) => {
      const data = JSON.parse(event.data);
      if (data.client === localStorage.getItem("clientPrefix")) {
        if (data.type === "coordinate") {
          const { latitude, longitude, azimuth, device } = data;

          if (isRadarActive && filteredDeviceIds?.includes(device)) {
            drawShadow(latitude, longitude, azimuth, device);
          }
        }
      }
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [isRadarActiveRef, isRadarActive]
  );

  /**
   *
   */
  useEffect(() => {
    webSocketManager.setOnMessageCallback(handleWebSocketMessage);

    return () => {
      webSocketManager.removeOnMessageCallback(handleWebSocketMessage);
    };
    //eslint-disable-next-line
  }, [webSocketManager, coordinates, deviceCoordinate, handleWebSocketMessage]);

  /**
   *
   */
  const handleSwitchRadar = () => {
    removeAllCircles(); // Clear existing circles when toggling
    setIsRadarActive(!isRadarActive);
  };

  /**
   *
   */
  const startDetector = async () => {
    // Iterate through the deviceCoordinate list
    if (deviceCoordinate.length > 0) {
      throwToastStart();
    }

    // Iterate through the deviceCoordinate list
    for (const device of deviceCoordinate) {
      //console.log("device", device);
      // Check if the coordinate property is not null
      if (device?.coordinate && device?.active && !device.virtual) {
        const { latitude, longitude, azimuth } = device?.coordinate;
        // Draw a circle for each device
        isRadarActive && drawShadow(latitude, longitude, azimuth, device.id);
      }
    }
  };

  /**
   *
   * @param {*} lat
   * @param {*} lng
   */
  const drawShadow = async (lat, lng, azimuth, deviceId) => {
    if (isNaN(lat) || isNaN(lng) || isNaN(azimuth)) {
      console.log("Invalid input values for latitude, longitude, or azimuth.");
      return; // Exit early if input values are invalid
    }
    const shadowDistance = 0.0015; // Adjust the distance as needed

    // Convert heading to radians
    const headingRad = (azimuth * Math.PI) / 180;
    // Calculate new coordinates based on heading and distance
    const newLat = lat && lat + shadowDistance * Math.cos(headingRad);
    const newLng = lng && lng + shadowDistance * Math.sin(headingRad);

    if (!shadowMarkerRefs.current[deviceId]) {
      // Create a new circle if it doesn't exist
      const newShadowMarker = createShadowMarker(newLat, newLng);
      newShadowMarker.addTo(mapRef.current);
      shadowMarkerRefs.current[deviceId] = newShadowMarker;
    } else {
      // Update the position if the circle already exists
      shadowMarkerRefs.current[deviceId].setLatLng([newLat, newLng]);
    }

    // remove the measurement out of the circle
    removeMesurementOutCircle(deviceId);

    isRadarActive && getEventOnCircle(newLat, newLng, deviceId, newLat, newLng);
  };

  /**
   *
   * @param {*} lat
   * @param {*} lng
   * @returns
   */
  const createShadowMarker = (lat, lng) => {
    const shadowMarker = L.circle([lat, lng], {
      radius: RADIUS_CIRCLE,
      weight: 0,
      fillColor: "#8c8cf0",
      fillOpacity: 0.1, //0.1
    });

    return shadowMarker;
  };

  /**
   *
   */
  const removeAllCircles = () => {
    Object.values(shadowMarkerRefs.current).forEach((circleMarker) => {
      mapRef.current.removeLayer(circleMarker);
    });

    //remove measurements
    removeAllMeasurement();
    // Clear the dictionary after removing all circles
    shadowMarkerRefs.current = {};
    // Clear the deviceDataDictionary
    deviceDataDictionary.current = {};
  };

  /**
   *
   */
  const throwToastStart = () => {
    toast.success(i18next.t("radar_start"), {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      hideProgressBar: true,
      theme: "colored",
      style: {
        top: windowSize[0] > 991 ? "40px" : "50px",
      },
    });
  };

  /**
   *
   * @param {*} lat
   * @param {*} lng
   */
  const getEventOnCircle = async (lat, lng, deviceId, circleLat, circleLng) => {
    const data = await callApi(lat, lng, circleLat, circleLng, deviceId);
    // Store the data in a dictionary with device ID as the key
    deviceDataDictionary[deviceId] = data;
  };

  /**
   *
   * @param {*} lat
   * @param {*} lng
   * @returns
   */
  const callApi = async (lat, lng, circleLat, circleLng, deviceId) => {
    let pageNumber = 1;
    let nextPage = true;
    let allData = [];

    while (nextPage) {
      let response;
      try {
        response = await getMeasurementRangeApi(
          lat,
          lng,
          RADIUS_CIRCLE,
          pageNumber
        );

        const rangeData = response.data.measurements;

        isRadarActive &&
          addMeasurements(rangeData, circleLat, circleLng, deviceId);

        // Update the itemList with the new items
        allData = allData.concat(rangeData);

        pageNumber++;
        // Check if there are more pages
        if (!response.data.nextPage) {
          nextPage = false;
        }
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
        if (err?.response?.status === 404) {
          nextPage = false;
        }
      }
    }

    // Return all the data for this device
    return allData;
  };

  /**
   *
   * @param {*} rangeData
   * @param {*} mapRef
   * @param {*} createIcon
   */
  const addMeasurements = (rangeData, circleLat, circleLng, deviceId) => {
    // Iterate through the data and create markers
    rangeData.forEach((item) => {
      const { latitude, longitude, contentType, content } = item;

      // Check if contentType is "BOOL" and content is false
      if (contentType === "BOOL" && content === "false") {
        // Skip marker creation for this item
        return;
      }
      // check if they are inside the circle
      const distance = calculateDistance(
        latitude,
        longitude,
        circleLat,
        circleLng
      );

      localStorage.setItem("circleLat", circleLat);
      localStorage.setItem("circleLng", circleLng);

      // Check if the marker is inside the circle
      if (distance <= RADIUS_CIRCLE) {
        const marker = L.marker([latitude, longitude], {
          icon: createIcon(item),
          isMeasurementMarker: true,
          deviceId: deviceId,
          latitude: latitude,
          longitude: longitude,
        });

        //markerGroup = L.featureGroup([marker]); // Create a group to hold the marker
        markerGroup.addLayer(marker);
        markerGroup.addTo(mapRef.current);
      }
    });
  };

  /**
   * Remove all markers from the map.
   */
  const removeAllMeasurement = () => {
    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.options.isMeasurementMarker) {
          mapRef.current.removeLayer(layer);
        }
      });
    }
  };

  /**
   *
   */
  const removeMesurementOutCircle = (deviceId) => {
    const circleLat = localStorage.getItem("circleLat");
    const circleLng = localStorage.getItem("circleLng");

    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.options.isMeasurementMarker) {
          const distance = calculateDistance(
            layer.options.latitude,
            layer.options.longitude,
            circleLat,
            circleLng
          );
          if (
            layer.options.deviceId === deviceId &&
            distance > RADIUS_CIRCLE_OUT
          ) {
            mapRef.current.removeLayer(layer);
          }
        }
      });
    }
  };

  /**
   *
   * @param {*} Coordinate
   * @param {*} rotationAngle
   * @returns
   */
  function createIcon(item) {
    const iconSize = [30, 30];
    // Map namespace values to corresponding icon paths
    const iconMappings = {
      "DAMP": water_1Pin,
      "WET": water_3Pin,
      "DRY": dryIcon,
      // Add more mappings as needed
    };
    // Get the icon path based on the namespace
    const iconPath = iconMappings[item.content] || defaultPin;

    return L.divIcon({
      className: "custom-icon",
      html: `<img src="${iconPath}"  width= "${iconSize[0]}px" height= "${iconSize[1]}px"/>`,
      iconSize: iconSize,
      iconAnchor: [13, 13], //[iconSize[0] / 2, iconSize[1] / 2]
    });
  }


  /**
   * Calculate the distance between two sets of coordinates using the Haversine formula.
   * @param {number} lat1 - Latitude of the first point.
   * @param {number} lng1 - Longitude of the first point.
   * @param {number} lat2 - Latitude of the second point.
   * @param {number} lng2 - Longitude of the second point.
   * @returns {number} - Distance between the two points in meters.
   */
  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const earthRadius = 6371000; // Earth's radius in meters
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    return distance;
  };

  return (
    <>
      <div className="radar_div1">
        <img
          src={isRadarActive ? radar_active : radar}
          onClick={handleSwitchRadar}
          height="20"
          className="d-inline-block"
          alt="radar"
        />
      </div>
    </>
  );
};
export default RadarMap;
