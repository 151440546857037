import i18next from "i18next";
import { toast } from "react-toastify";
import { iconloading } from "./iconStandard";

const CustomToast = ({ message, icon }) => (
    <div style={{ display: 'flex', alignItems: 'center', width: "300px" }}>
        <img src={icon} alt="Custom Loading Icon" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
        <span>{message}</span>
    </div>
);
const TimeOutToast = () => {


    toast(<CustomToast message={i18next.t('noCnx')} icon={iconloading} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 102000,
        hideProgressBar: true,
        closeOnClick: false,
        theme: 'colored',
        style: {
            top: '40px',
            zIndex: 99999
        },
    });

    const timeoutId = setTimeout(() => {
        window.location.reload(); // Reload the page after 5 seconds
    }, 100000);

    return () => clearTimeout(timeoutId);


};

export default TimeOutToast;