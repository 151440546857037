/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import TirePopUp from "./TirePopUp";
//import html2canvas from "html2canvas";
//import { jsPDF } from "jspdf";
import {
  iconAlertGood,
  iconAlertWarning,
  iconAlertError,
} from "../../utils/iconAlert.js";
import "../../assets/css/deviceDetailsView.css";
import useWindowSize from "../../utils/useWindowSize";
import {
  getMeasurementByNameSpaceApi,
  getTiresMeasurementApi,
} from "../../hooks/measurementApi";
import { ChangeDataTires } from "../../utils/changeDataWS";
import TimeOutToast from "../../utils/TimeOutToast.js";
import HistoryWarnings from "./HistoryWarnings.js";

/**
 * This component represents the the DeviceDetailsView
 * represent the dynamic tires wih thier infos.
 *
 * @author hatem sfar
 *
 */
export default function OverView({ device }) {
  const [tires, setTires] = useState([]);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const windowSize = useWindowSize();
  const [buttonChecked, setButtonChecked] = useState(true);
  const [isBlur, setIsBlur] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isHistoryPopupOpen, setHistoryIsPopupOpen] = useState(false);
  const [dataInfo, setDataInfo] = useState(null);

  /**
   *
   */
  useEffect(() => {
    getTires();
    //eslint-disable-next-line
  }, []);

  /**
   * to handle the popup of the tire
   * @param {*} p
   */
  const handleOpenPopup = (p) => {
    setDataInfo(p);
    setIsPopupOpen(true);
  };

  /**
   * to close the popup
   */
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  /**
   * to get the tires of the selected device
   */
  const getTires = () => {
    getTiresMeasurementApi(id)
      .then((response) => {
        if (response.error) {
          setTires([]);
        } else {
          // Transform data
          const transformedData = response?.measurements?.flatMap(
            (measurement) =>
              measurement.content.split(",").map((val) => {
                const [var1, axle, direction, position] = val.split(".");
                return {
                  var1,
                  axle: Number(axle),
                  direction,
                  position: Number(position),
                };
              })
          );

          // Map over transformedData array and make axios call for each position
          const axiosCalls = transformedData?.map(async (position) => {
            const position_namespace =
              "de.okit.automotive.tpms." +
              position.var1 +
              "." +
              position.axle +
              "." +
              position.direction +
              "." +
              position.position +
              ".*" +
              "," +
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              "." +
              position.direction +
              "." +
              position.position +
              ".*" +
              "," +
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              ".Trailer" +
              "," +
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              ".Steering";
            return await getMeasurementByNameSpaceApi(id, position_namespace)
              .then((response) => {
                response &&
                  response?.forEach((data) => {
                    if (data && data.namespace.endsWith("Pressure")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.Pressure = data?.measurements[0]?.content;
                        position.Timestamp_Pressure =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("Temperature")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.Temperature = data?.measurements[0]?.content;
                        position.Timestamp_Temperture =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureLowerWarn")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureLowerWarn =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureLowerWarn =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureLowerAlarm")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureLowerAlarm =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureLowerAlarm =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureUpperWarn")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureUpperWarn =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureUpperWarn =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureUpperAlarm")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureUpperAlarm =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureUpperAlarm =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("Steering")) {
                      if (data.measurements && data.measurements.length > 0) {
                        const stringValue = data?.measurements[0]?.content;
                        const booleanValue = stringValue === "true";
                        position.Steering = Boolean(booleanValue);
                        position.Timestamp_Steering =
                          data.measurements &&
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("Trailer")) {
                      if (data.measurements && data.measurements.length > 0) {
                        const stringValue = data?.measurements[0]?.content;
                        const booleanValue = stringValue === "true";
                        position.Trailer = Boolean(booleanValue);
                        position.Timestamp_Trailer =
                          data.measurements &&
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                  });
              })
              .catch((error) => {
                if (error.response && error.response.status === 504) {
                  console.error(
                    "Gateway Time-out: The server took too long to respond."
                  );
                  TimeOutToast();
                  setIsLoading(false);
                }
              });
          });

          if (!axiosCalls) {
            setShow(true);
            setIsLoading(false);
          }
          // Wait for all axios calls to complete using Promise.all()
          axiosCalls &&
            Promise.all(axiosCalls).then(() => {
              setTires(transformedData ? transformedData : []);
              setShow(true);
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
          setIsLoading(false);
        }
        console.log(err);
        setShow(true);
        setIsLoading(false);
      });
  };
  /**
   * to handle the switch button
   */
  function toggleValue() {
    setIsBlur(true);
    setButtonChecked(!buttonChecked);
    setTimeout(() => {
      setIsBlur(false);
    }, 1000);
  }

  /**
   *
   */
  const lineStyle = {
    gridColumn: "3 / 4", // span from column 3 to column 4
    gridRow: "1 / -1", // span from row 1 to the last row
    //borderLeft: '2px solid gray',
    zIndex: "100", // set a high value to put the line in front of other elements
    justifySelf: "center",
  };

  /**
   *
   */
  const gridStyle = {
    display: "grid",
    gridTemplateColumns:
      windowSize[0] > 991 ? "1fr 1fr 30%  1fr  1fr" : "1fr 1fr 30% 1fr  1fr",
    //gridAutoRows: 'minmax(100px, auto)', // adjust minimum row height here
    //gridTemplateRows: `repeat(${[...new Set(positions.map((p) => p.axle))].length}, 1fr)`, // calculate the number of unique axle positions and add 4 extra rows
    gap: "1px",
    padding: "10px",
    width: windowSize[0] < 991 ? "100%" : "65%",
  };

  /**
   *
   */
  const cardStyle = {
    background: "#e5e5e5",
    width: windowSize[0] > 991 ? "100px" : "100%",
    height: windowSize[0] > 991 ? "130px" : "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    cursor: "pointer",
    zIndex: "100",
  };

  const getPositionRow = (currentPosition) => {
    //console.log("currentPosition", currentPosition);
    if (currentPosition?.Trailer && !currentPosition?.Steering) {
      return currentPosition?.axle + 60;
    }
    if (currentPosition?.Trailer) {
      return currentPosition?.axle + 0;
    }
    if (!currentPosition?.Trailer && !currentPosition?.Steering) {
      return currentPosition?.axle + 20;
    }

    if (currentPosition?.Steering) {
      return currentPosition?.axle;
    }
  };

  /**
   * to save the page in PDF format
   */
  /*
  const handlePdfSave = () => {
    //setVisible(false)
    const element = document.body;
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "pt", "a3");
      pdf.addImage(imgData, "jpeg", 0, 0);
      pdf.save("DetailDevice.pdf");
    });
  };*/

  /**
   * to handle the popup of the tire
   * @param {*} p
   */
  //eslint-disable-next-line
  const handleHistoryOpenPopup = (p) => {
    setDataInfo(p);
    setHistoryIsPopupOpen(true);
  };

  /**
   * to close the popup
   */
  const handleHistoryClosePopup = () => {
    setHistoryIsPopupOpen(false);
  };
  return (
    <>
      <div className="">
        <h3
          style={{ marginLeft: "15px", marginTop: "10px" }}
          className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
        >
          {i18next.t("OverViewTitle")}{" "}
        </h3>
        <ChangeDataTires
          tires={tires}
          setTires={setTires}
          generateAxleWeightList={null}
          device={device}
        />
        {/*

                <div style={{ textAlign: "center" }}>
                    <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-900"> {device.name} </h6>
                    <hr style={{ width: "20%", margin: "auto" }}></hr>
                </div>
                <br /><br />
            
    */}
        <br />
        <br />
        <div style={{ textAlign: "-webkit-center" }}>
          {tires.length !== 0 && (
            <>
              <div
                style={{ marginTop: windowSize[0] < 991 ? "-50px" : "-20px" }}
              >
                <div style={{ textAlign: "-webkit-left" }}>
                  <Link to={"https://devdocs.wikiland.de/"}>
                    <div
                      style={{
                        display: "inline",
                        borderRadius: "60px",
                        boxShadow: "0 0 2px #888",
                        padding: "0.5em 0.6em",
                        zoom: 1.5,
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-question-circle"></i>
                    </div>
                  </Link>

                  {/*
                 //TODO: This feature waiting for backend 
                 
                  <div
                    style={{
                      display: "inline",
                      borderRadius: "60px",
                      boxShadow: "0 0 2px #888",
                      padding: "0.5em 0.6em",
                      zoom: 1.5,
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => handleHistoryOpenPopup(tires)}
                  >
                    <i className="fa fa-history"></i>
                  </div>*/}
                </div>

                <div
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: "-43px",
                  }}
                >
                  <BootstrapSwitchButton
                    size="lg"
                    width={160}
                    checked={buttonChecked}
                    onstyle="primary"
                    offstyle="secondary"
                    onlabel={i18next.t("Pressure")}
                    offlabel={i18next.t("Temperature")}
                    onChange={() => {
                      toggleValue();
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {isPopupOpen && (
            <TirePopUp
              isOpen={isPopupOpen}
              onClose={handleClosePopup}
              dataInfo={dataInfo}
              deviceId={device}
            />
          )}

          {isHistoryPopupOpen && (
            <HistoryWarnings
              isOpen={isHistoryPopupOpen}
              onClose={handleHistoryClosePopup}
              dataInfo={dataInfo}
              deviceId={device}
            />
          )}

          <br />
          <br />

          {isLoading && (
            <>
              <div
                className="loading"
                style={{
                  position: "absolute",
                  //background: "none",
                  //zIndex: "-1"
                }}
              >
                <div className="loader"></div>
                <div className="text">{i18next.t("Loading")}...</div>
              </div>
            </>
          )}
          <div style={{ textAlign: "-moz-center" }}>
            <div id="grid" style={gridStyle}>
              {Array.isArray(tires)
                ? tires?.map((position, i) => {
                    const gridRow = getPositionRow(position);
                    const pressure =
                      position?.Pressure && Number(position?.Pressure);
                    const lowerAlarm =
                      position?.PressureLowerAlarm &&
                      Number(position?.PressureLowerAlarm);
                    const upperAlarm =
                      position?.PressureUpperAlarm &&
                      Number(position?.PressureUpperAlarm);
                    const lowerWarn =
                      position?.PressureLowerWarn &&
                      Number(position?.PressureLowerWarn);
                    const upperWarn =
                      position?.PressureUpperWarn &&
                      Number(position?.PressureUpperWarn);

                    return (
                      <React.Fragment key={i}>
                        <div
                          className="lineStyle"
                          style={{
                            gridRow: gridRow,
                          }}
                        ></div>

                        <hr
                          className="horizontalLineStyle"
                          style={{
                            gridRow: gridRow,
                            border: position?.Trailer
                              ? "solid 2px #292727"
                              : "solid 2px gray",
                          }}
                        ></hr>

                        <div
                          onClick={() => handleOpenPopup(position)}
                          style={{
                            ...cardStyle,
                            //!position?.Pressure || position?.Pressure < position?.PressureLowerAlarm || position?.Pressure > position?.PressureUpperAlarm ? "solid 5px red" : position.Pressure < position.PressureLowerWarn || position.Pressure > position.PressureUpperWarn ? "solid 5px orange" : "solid 5px #18bd18"
                            border:
                              !isNaN(pressure) &&
                              !isNaN(lowerAlarm) &&
                              !isNaN(upperAlarm) &&
                              !isNaN(lowerWarn) &&
                              !isNaN(upperWarn)
                                ? pressure <= lowerAlarm ||
                                  pressure >= upperAlarm
                                  ? "solid 5px red"
                                  : pressure <= lowerWarn ||
                                    pressure >= upperWarn
                                  ? "solid 5px orange"
                                  : "solid 5px #18bd18"
                                : "solid 5px orange",

                            transform: position?.Steering && "rotate(30deg)",
                            gridColumn:
                              position?.direction === "left"
                                ? position?.position
                                : position?.direction === "right" &&
                                  position?.position === 1
                                ? position?.position + 4
                                : position?.position + 2, // left column if direction is left, right column if direction is right
                            gridRow: gridRow, // row number is equal to the axle number
                            gap: position?.position === 2 && "2/4", // span two columns for positions 2 and 3
                          }}
                        >
                          <h3
                            style={{
                              color: "black",
                              transform: position?.Steering && "rotate(-30deg)",
                              //marginTop: !(position.Pressure < position.PressureLowerWarn) && !(position.Pressure > position.PressureUpperWarn) && !(position.Pressure < position.PressureLowerAlarm) && !(position.Pressure > position.PressureUpperAlarm) && "38px"
                            }}
                          >
                            {" "}
                            {isNaN(pressure) ||
                            isNaN(lowerAlarm) ||
                            isNaN(upperAlarm) ||
                            isNaN(lowerWarn) ||
                            isNaN(upperWarn) ? (
                              <>
                                <img
                                  src={iconAlertWarning}
                                  height="30"
                                  className="d-inline-block"
                                  alt="warning signe"
                                />
                                <br />
                                <b
                                  className={isBlur ? "value blur" : " value"}
                                  style={{
                                    fontSize:
                                      windowSize[0] > 1000 ? "20px" : "15px",
                                    marginLeft: "-15px",
                                    // display: isBlur && "none"
                                  }}
                                >
                                  {buttonChecked ? (
                                    <>
                                      {isBlur
                                        ? position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                          ? Number(position?.Temperature)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " °C"
                                          : "-"
                                        : position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                        ? Number(position?.Pressure)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " Ba"
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      {isBlur
                                        ? position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                          ? Number(position?.Pressure)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " Ba"
                                          : "-"
                                        : position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                        ? Number(position?.Temperature)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " °C"
                                        : "-"}
                                    </>
                                  )}
                                </b>
                              </>
                            ) : Number(position?.Pressure) <=
                                position?.PressureLowerAlarm ||
                              Number(position?.Pressure) >=
                                position?.PressureUpperAlarm ? (
                              <>
                                <img
                                  src={iconAlertError}
                                  height="30"
                                  className="d-inline-block"
                                  alt="danger signe"
                                />
                                <br />
                                <b
                                  className={isBlur ? "value blur" : " value"}
                                  style={{
                                    fontSize:
                                      windowSize[0] > 1000 ? "20px" : "15px",
                                    marginLeft: "-15px",
                                    // display: isBlur && "none"
                                  }}
                                >
                                  {buttonChecked ? (
                                    <>
                                      {isBlur
                                        ? position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                          ? Number(position?.Temperature)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " °C"
                                          : "-"
                                        : position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                        ? Number(position?.Pressure)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " Ba"
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      {isBlur
                                        ? position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                          ? Number(position?.Pressure)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " Ba"
                                          : "-"
                                        : position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                        ? Number(position?.Temperature)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " °C"
                                        : "-"}
                                    </>
                                  )}
                                </b>
                              </>
                            ) : Number(position?.Pressure) <=
                                Number(position?.PressureLowerWarn) ||
                              Number(position?.Pressure) >=
                                Number(position?.PressureUpperWarn) ? (
                              <>
                                <img
                                  src={iconAlertWarning}
                                  height="30"
                                  className="d-inline-block"
                                  alt="warning signe"
                                />
                                <br />
                                <b
                                  className={isBlur ? "value blur" : " value"}
                                  style={{
                                    fontSize:
                                      windowSize[0] > 1000 ? "20px" : "15px",
                                    marginLeft: "-15px",
                                  }}
                                >
                                  {" "}
                                  {buttonChecked ? (
                                    <>
                                      {isBlur
                                        ? position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                          ? Number(position?.Temperature)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " °C"
                                          : "-"
                                        : position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                        ? Number(position?.Pressure)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " Ba"
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      {isBlur
                                        ? position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                          ? Number(position?.Pressure)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " Ba"
                                          : "-"
                                        : position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                        ? Number(position?.Temperature)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " °C"
                                        : "-"}
                                    </>
                                  )}
                                </b>
                              </>
                            ) : (
                              <>
                                <img
                                  src={iconAlertGood}
                                  height="30"
                                  className="d-inline-block"
                                  alt="greenLED signe"
                                />
                                <br />
                                <b
                                  className={isBlur ? "value blur" : " value"}
                                  style={{
                                    fontSize:
                                      windowSize[0] > 1000 ? "20px" : "15px",
                                    marginLeft: "-15px",
                                  }}
                                >
                                  {" "}
                                  {buttonChecked ? (
                                    <>
                                      {isBlur
                                        ? position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                          ? Number(position?.Temperature)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " °C"
                                          : "-"
                                        : position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                        ? Number(position?.Pressure)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " Ba"
                                        : "-"}
                                    </>
                                  ) : (
                                    <>
                                      {isBlur
                                        ? position?.Pressure !== null &&
                                          !isNaN(position.Pressure)
                                          ? Number(position?.Pressure)
                                              ?.toFixed(2)
                                              .replace(".", ",") + " Ba"
                                          : "-"
                                        : position?.Temperature !== null &&
                                          !isNaN(position.Temperature)
                                        ? Number(position?.Temperature)
                                            ?.toFixed(2)
                                            .replace(".", ",") + " °C"
                                        : "-"}
                                    </>
                                  )}
                                </b>
                              </>
                            )}{" "}
                          </h3>
                        </div>

                        <div style={lineStyle}></div>
                      </React.Fragment>
                    );
                  })
                : null}
            </div>
          </div>

          {!isLoading && tires.length === 0 ? (
            show ? (
              <>
                <div
                  style={{ marginTop: "20px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="text-center">
                    <p className="fs-3">
                      {" "}
                      <span className="text-danger"></span>
                      {i18next.t("noTires")}{" "}
                    </p>
                    <a href="/devices" className="btn btn-primary">
                      {i18next.t("GoBack")}
                    </a>
                  </div>
                </div>
              </>
            ) : null
          ) : null}
        </div>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
      </div>
    </>
  );
}
