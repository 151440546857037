/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import axios from "axios";
import apiList from "../utils/apiClient.js";

/**
 * This component implements the api's call
 * 
 * @author hatem sfar
 *
 */
const clientPrefixApi = async () => {
    const { data } = await axios.get(apiList.DeviceUnique);
    return data;
}
const clientPrefixServiceProviderApi = async () => {
    const { data } = await axios.get(apiList.ClientServiceProvider);
    return data;
}
const getSessionApi = async () => {
    const { data } = await axios.get(apiList.SessionClientPrefix);
    return data;
}
const setSessionApi = async (client) => {
    const { data } = await axios.get(apiList.UpdateSession + client);
    return data;
}

const clientPrefixUserApi = async () => {
    const { data } = await axios.get(apiList.ClientUser);
    return data;
}

const clientPrefixUserUpdateApi = async (client) => {
    const { data } = await axios.get(apiList.ClientUpdate, {
        params: {
            data: client
        }
    });
    return data;
}


export {
    clientPrefixApi,
    clientPrefixUserApi,
    clientPrefixServiceProviderApi,
    clientPrefixUserUpdateApi,
    getSessionApi,
    setSessionApi
}
