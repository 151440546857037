import React, { createContext, useContext, useEffect, useRef } from "react";
import WebSocketManager from "./WebSocketManager";

const WebSocketContext = createContext(null);

export function WebSocketProvider({ children }) {
  const webSocketManagerRef = useRef(null);

  if (!webSocketManagerRef.current) {
    webSocketManagerRef.current = new WebSocketManager(
      process.env.REACT_APP_SOCKET_URL
    );
  }

  useEffect(() => {
    return () => {
      // Do not close the WebSocket connection here
      // Instead, let WebSocketManager handle the cleanup
    };
  }, []);

  return (
    <WebSocketContext.Provider value={webSocketManagerRef.current}>
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket() {
  const webSocketManager = useContext(WebSocketContext);
  if (!webSocketManager) {
    throw new Error(
      "useWebSocket must be used within a WebSocketProvider."
    );
  }
  return webSocketManager;
}
