import React from 'react'
import breakIcon from "../../assets/images/break.svg";
import break_active from "../../assets/images/break_active.svg";

export default function BreaksPolylines({isBreakActive, setIsBreakActive}) {


    /**
    * 
    */
    const handleSwitchBreak = () => {
        setIsBreakActive(!isBreakActive);
    };

    return (
        <div className='break_div1'>
            <img
                src={isBreakActive ? break_active : breakIcon}
                onClick={handleSwitchBreak}
                height="20"
                className="d-inline-block"
                alt="radar"
            />
        </div>
    )
}
