/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React from "react";
import i18next from "i18next";
import serviceUnavailableImage from "../assets/images/noCnx.png";

/**
 * This component implements notFound interface
 *
 * @author hatem sfar
 *
 */
const ServerNotReachable = (props) => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="d-flex align-items-center justify-content-center">
        <div className="text-center">
          <img
            src={serviceUnavailableImage}
            width="100px"
            height="100px"
            alt="Service Unavailable"
            className="img-fluid"
          />
          <p className="fs-3">{i18next.t("serverdown")}</p>
          {/*
          <p className="lead">{i18next.t("serverdownmessage")}</p>
          */}
          <p className="lead">{i18next.t("serverdownmessage2")}</p>
        </div>
      </div>
    </>
  );
};
export default ServerNotReachable;
