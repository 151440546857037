/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */

/**
 * This component represents the authorization for the user by his role
 *
 * @author hatem sfar
 *
 */
const auth = (permissions) => {
  const roles = localStorage.getItem("roles");
  //console.log("roles ", roles);

  if (!roles) {
    return false;
  }
  const rolesArray = roles.split(",");
  const prefixLength = rolesArray[0].indexOf("_") + 1; // Find the index of the underscore and add 1 to get the prefix length
  const userRoles = rolesArray.map((role) => role.substring(prefixLength));
  const hasPermission = userRoles.some((role) => permissions.includes(role));
  if (hasPermission) {
    return true;
  } else {
    return false;
  }
};

export default auth;
