/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./components/translation/i18n"
import i18next from "i18next"
import { CasUserContextProvider } from "./context/casUserContext";

/**
 * This component implements the index.js
 * 
 * @author hatem sfar
 *
 */
const lang = localStorage.getItem('lang') || 'de';
i18next.changeLanguage(lang);
document.documentElement.lang = lang;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <CasUserContextProvider>
      <App />
    </CasUserContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
