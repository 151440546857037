import React from 'react'
import topSpeed from "../../assets/images/topSpeed.svg";
import topSpeed_active from "../../assets/images/topSpeed_active.svg";

export default function TopSpeedsPolylines({ isTopSpeedActive, setIsTopSpeedActive }) {


    /**
    * 
    */
    const handleSwitchTopSpeed = () => {
        setIsTopSpeedActive(!isTopSpeedActive);
    };

    return (
        <div className='topspeed_div1'>
            <img
                src={isTopSpeedActive ? topSpeed_active : topSpeed}
                onClick={handleSwitchTopSpeed}
                height="20"
                className="d-inline-block"
                alt="radar"
            />
        </div>
    )
}
