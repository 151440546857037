import React from 'react'
import trace from "../../assets/images/trace.svg";
import trace_active from "../../assets/images/trace_active.svg";

export default function TracePolylines({ isTraceActive, setIsTraceActive }) {

    /**
    * 
    */
    const handleSwitchTrace = () => {
        setIsTraceActive(!isTraceActive);
    };
    return (
        <div className='trace_div1'>
            <img
                src={isTraceActive ? trace_active : trace}
                onClick={handleSwitchTrace}
                height="20"
                className="d-inline-block"
                alt="radar"
            />
        </div>
    )
}
