/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import common_en from "./en.json"
import common_de from "./de.json"

/**
 * This component represents the the librery
 * to translate the vcm app
 * 
 * @author hatem sfar
 *
 */
const resources ={
    de:  {
        translation: common_de
    },
    en:  {
        translation: common_en
    }
    
}

i18n
.use(initReactI18next)
.init({
    resources,
    lang: 'de',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});
export default i18n;