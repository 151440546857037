import { toast } from "react-toastify";
import { getDeviceByIdApi } from "../hooks/deviceApi";
import useWindowSize from "./useWindowSize";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../components/webSocket/WebSocketContext";
import { useEffect } from "react";

export function ToastAlert() {
    const navigate = useNavigate();
    const windowSize = useWindowSize()
    const webSocketManager = useWebSocket();

    useEffect(() => {
        const handleWebSocketMessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.client === localStorage.getItem("clientPrefix")) {
                if (data.type === "measurement") {
                    if (data.node.includes("Pressure")) {
                        //console.log("data: ", data);
                        const storageStatus = localStorage.getItem(`alert-${data.device}`);
                        //console.log("toast data: ", data)
                        // console.log("oldalert ", oldalert)

                        getDeviceByIdApi(data.device)
                            .then((response) => {
                                const deviceStatus = response.alert;
                                const name = response.name;
                                const tireName = convertToString(data.node)
                                const severityE = "Alarm"; // You can customize this based on your needs
                                const severityW = "Alarm";
                                const deviceNamecnst = name; // Get the device name from response or variables
                                const axle = tireName; // Get axle info from response or variables
                                const deviceIdcnst = data.device; // Get deviceId from response or variables
                                const msgError = `${severityE}: ${i18next.t('Refenfruck')} \n ${deviceNamecnst}, ${axle}`;
                                const msgWarning = `${severityW}: ${i18next.t('Refenfruck')}\n ${deviceNamecnst}, ${axle}`;
                                if (response.active) {
                                    if (deviceStatus !== storageStatus) {
                                        // console.log("yes diff", deviceStatus , " ** ", storageStatus)
                                        localStorage.setItem(`alert-${data.device}`, deviceStatus)
                                        if (deviceStatus === "error") {
                                            toast.error(msgError, {
                                                position: toast.POSITION.TOP_RIGHT,
                                                autoClose: 60000,
                                                theme: "colored",
                                                onClick: () => {
                                                    navigate(`/devices/detail/${deviceIdcnst}`)
                                                },
                                                style: {
                                                    top: windowSize[0] > 991 ? "40px" : "50px",
                                                    right: windowSize[0] > 991 ? "40px" : "-20%",
                                                    width: windowSize[0] > 991 ? "100%" : "60%",
                                                    zIndex: "1000"
                                                }
                                            });
                                        }
                                        else if (deviceStatus === "warning") {
                                            toast.warning(msgWarning, {
                                                position: toast.POSITION.TOP_RIGHT,
                                                autoClose: 60000,
                                                onClick: () => { navigate(`/devices/detail/${deviceIdcnst}`) },
                                                theme: "colored",
                                                style: {
                                                    top: windowSize[0] > 991 ? "40px" : "50px",
                                                    right: windowSize[0] > 991 ? "40px" : "-20%",
                                                    width: windowSize[0] > 991 ? "100%" : "60%",
                                                    zIndex: "1000"
                                                }
                                            });
                                        }
                                    }
                                }
                            }).catch((err) => {
                                console.log(err);
                            });
                    }
                }
            }
        };

        webSocketManager.setOnMessageCallback(handleWebSocketMessage);

        return () => {
            webSocketManager.removeOnMessageCallback(handleWebSocketMessage);
        };
        //eslint-disable-next-line
    }, [webSocketManager]);
}

/**
 * 
 * @param {*} tireName 
 * @returns 
 */
export function convertToString(tireName) {
    // Extracting the desired string
    const extractedString = tireName.split('.').slice(4, 8).join('.');
    const parts = extractedString.split('.');
    const axle = parts[1];
    const side = parts[2] === 'left' ? i18next.t('Links') : i18next.t('Rechte')
    // console.log("side: ", parts[2])
    const position = parts[3] === '1' ? i18next.t('Außen') : i18next.t('Innen');

    return `Achse ${axle},  ${side.charAt(0).toUpperCase() + side.slice(1)}, ${position}`;
};

