/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich gesch├╝tzt.
 */
import React, { useState, useEffect } from "react";
import i18next from 'i18next';
import { Container } from "react-bootstrap";
import { Button, Input } from "antd";
import DatePicker from 'react-datepicker';
import "../../assets/css/react-datepicker.css";
import { Link } from "react-router-dom";
import { Tooltip } from 'antd';
import moment from "moment";
import auth from "../../auth/auth";
import ROLES from "../../utils/roles";
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';
import { getDeviceApi } from "../../hooks/deviceApi";
import { getAllNameSpacesApi, getNameSpaceListApi } from "../../hooks/nameSpaceList";
import { getMeasurementByNameSpaceApi, setMeasurementByNameSpaceApi } from "../../hooks/measurementApi";
import TimeOutToast from "../../utils/TimeOutToast";



/**
 * This component allows the user to update measurements for a selected device and namespace.
 * It displays a form where the user can select a device and namespace, enter a new value for the measurement,
 * and update the measurement value.
 *  
 * @author hatem sfar
 *
 */
const Measurement = () => {

    const [devices, setDevices] = useState([]);
    const [deviceid, setDeviceid] = useState(null);
    const [deviceInfo, setDeviceInfo] = useState();
    const [nodes, setNodes] = useState([]);
    const [nodeInfo, setNodeInfo] = useState(null);
    const [nodeid, setNodeid] = useState(null);
    const [nodeType, setNodeType] = useState('');
    //eslint-disable-next-line
    const [value, setValue] = useState();
    const [obj, setObj] = useState("");
    const [measurementDate, setMeasurementDate] = useState();
    const [isLoading, setIsLoading] = useState(false);
    //eslint-disable-next-line
    const [ButtonEnable, setButtonEnable] = useState(true)
    const [allnodes, setAllNodes] = useState([]);
    const [showAllNamespaces, setShowAllNamespaces] = useState(false);



    useEffect(() => {
        getDeviceList()
    }, []);

    /**
     * get the device list
     */
    const getDeviceList = async () => {
        getDeviceApi()
            .then((response) => {
                setDevices(response ? response : []);
            })
            .catch((err) => {
                if (err.response && err.response.status === 504) {
                    console.error("Gateway Time-out: The server took too long to respond.");
                    TimeOutToast()
                    setIsLoading(false)
                }
                console.log(err);
            });

    };
    /**
     * to handle the selected device
     * @param {*} event 
     */
    const handleDevice = (event) => {
        setDeviceid(event);
        setDeviceInfo(event.value)
    }

    useEffect(() => {
        //eslint-disable-next-line
        getAllNamespaces()
        deviceid && getNamespaceList()
        nodeid && getMeasurement()
        //eslint-disable-next-line
    }, [deviceid, nodeid]);

    /**
     * get namespace by the selected device
     */
    const getNamespaceList = async () => {

        getNameSpaceListApi(deviceid.value.id)
            .then((response) => {
                if (response.length > 0) {
                    const sortedNamespaces = response.sort((a, b) => {
                        const labelA = a.node.namespace.slice().toLowerCase();
                        const labelB = b.node.namespace.slice().toLowerCase();
                        return labelA.localeCompare(labelB);
                    });
                    setNodes(sortedNamespaces ? sortedNamespaces : []);
                } else {
                    setNodeid("")
                }
            })
            .catch((err) => {
                console.log(err);
            });

    };
    /**
     * handle the selected namespace
     * @param {*} event 
     */
    const handleNode = (event) => {
        if (!event) {
            setNodeid("")
            setNodeInfo(null)
            setButtonEnable(true)
        } else {
            setNodeid(event);
            setNodeInfo(event.value)
            setNodeType(event.value.contentType)

        }
    }
    /**
     * handle the format of date
     * @param {*} inputDate 
     * @returns 
     */
    const formatDate = (inputDate) => {
        // Use Moment.js to parse the input date string
        const date = moment(inputDate, ['ddd MMM DD HH:mm:ss z YYYY', 'YYYY-MM-DD HH:mm:ss.SSS']);

        // Format the date string in the desired format
        return date.format('YYYY-MM-DD');
    }
    /**
     * handle the input in the fields
     * @param {*} e 
     */
    const handleInputChange = (e) => {
        if (e.target) {
            if (obj.content === e.target.value) {
                setButtonEnable(true)
            } else {
                setButtonEnable(false)
            }
            setValue(e.target.value);
        } else {
            const isEq = new Date(e).toISOString().substring(0, 10) === obj.content.substring(0, 10);
            if (isEq) {
                setButtonEnable(true)
                setValue(e);
            } else {
                setValue(e);
                setButtonEnable(false)
            }


        }
    };
    /**
     * handle the boolean type inputs
     * @param {*} e 
     */
    const handleInputChangeBool = (e) => {
        if (obj.content === e.target.checked) {
            setButtonEnable(true)
        } else {
            setButtonEnable(false)
        }
        setValue(e.target.checked.toString());
    };
    /**
     * get measurement by selected device and the selected namepsace
     */
    const getMeasurement = async () => {

        getMeasurementByNameSpaceApi(deviceid.value.id, nodeid.value.namespace.toString())
            .then((response) => {
                setObj(response[0].measurements[0])
                if (response[0].namespace.endsWith("Date")) {
                    const formattedDatenew = formatDate(response[0].measurements[0].content)
                    setValue(formattedDatenew)
                } else {
                    setValue(response[0].measurements[0].content ? response[0].measurements[0].content : "")
                }
                const date = new Date(response[0].measurements[0].measurementdate);
                const formattedDate = `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}  ${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
                setMeasurementDate(formattedDate)
            })
            .catch((error) => {
                if (error.response && error.response.status === 504) {
                    console.error("Gateway Time-out: The server took too long to respond.");
                    TimeOutToast()
                    setIsLoading(false)
                }
                setValue("")
            })
    }
    /**
     * get all the namesapce from DB
     */
    const getAllNamespaces = async () => {
        getAllNameSpacesApi()
            .then((response) => {
                const sortedNamespaces = response.sort((a, b) => {
                    const labelA = a.namespace.slice().toLowerCase();
                    const labelB = b.namespace.slice().toLowerCase();
                    return labelA.localeCompare(labelB);
                });
                setAllNodes(sortedNamespaces ? sortedNamespaces : [])
            }).catch((err) => {
                console.log(err)
            })
    }
    /**
     * update the value of the measurement
     */
    const setMeasurement = async () => {
        if (auth([ROLES.ADMIN, ROLES.OPERATOR])) {
            setIsLoading(true)
            setMeasurementByNameSpaceApi(deviceid.value.id, value, nodeid.value.namespace.toString())
                .then((response) => {
                    getMeasurement()
                    setButtonEnable(true)
                    setIsLoading(false)
                }).catch((err) => {
                    console.log(err)
                })
        }
    };
    /**
     * to handle the checkbox
     * @param {*} event 
     */
    const handleCheckboxChange = (event) => {
        setShowAllNamespaces(event.target.checked);
    };

    const deviceOptions = devices && devices?.map((device) => ({
        value: device,
        label: device.name,
    }));

    const nodeOptions = nodes && nodes?.map((node) => ({
        value: node.node,
        label: node.node.namespace,
    }));

    const nodeAllOptions = allnodes && allnodes?.map((node) => ({
        value: node,
        label: node.namespace,
    }));


    return (
        <>
            <Container className="content">
                <br />

                <div className="row">
                    <div className="col-sm-12">
                        <h5 className="mt-4 mb-4 fw-bold">
                            <Link
                                to={"/devices"}
                            >
                                <div
                                    style={{
                                        display: "inline",
                                        borderRadius: "60px",
                                        boxShadow: "0 0 2px #888",
                                        padding: "0.5em 0.6em",
                                        zoom: 0.7,
                                        marginRight: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <i className="fa fa-arrow-left"></i>
                                </div>
                            </Link>
                            {" "} {i18next.t('WebMapApi')}  { }</h5>
                        <div className="row mb-3">
                            <div className="form-group col-md-4">
                                <div>
                                    <label className="mb-2">{i18next.t('Device')} </label>
                                    <Select
                                        value={deviceid}
                                        onChange={handleDevice}
                                        options={deviceOptions}
                                        placeholder={i18next.t('SelectDevice')}
                                        isSearchable={true}
                                    />
                                </div>
                                <div style={{ marginTop: "15px" }} >
                                    <label className="mb-2">{i18next.t('Namespace')} </label>
                                    <Select
                                        value={nodeid}
                                        onChange={handleNode}
                                        options={showAllNamespaces ? nodeAllOptions : nodeOptions}
                                        placeholder={i18next.t('SelectNamespace')}
                                        isSearchable={true}
                                    />
                                    {deviceid &&
                                        <>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={showAllNamespaces}
                                                    onChange={handleCheckboxChange}
                                                />
                                                {" "} {i18next.t('Showall')}
                                            </label>
                                        </>}
                                </div>

                                {nodeid && (
                                    <>
                                        <div style={{ marginTop: "10px" }} >
                                            <label className="mb-2">{i18next.t('Measurement Date')} </label><br />

                                            <font className="mb-2">{measurementDate} </font>
                                        </div>

                                        <div style={{ marginTop: "10px" }} >
                                            <label className="mb-2">{i18next.t('Value')} </label>
                                            {(
                                                nodeType.toLowerCase() === "string" ||
                                                nodeType.toLowerCase() === "float" ||
                                                nodeType.toLowerCase() === "integer" ||
                                                nodeType.toLowerCase() === "number") && (
                                                    <>
                                                        <div>
                                                            <Input style={{ width: "80%", height: "38px" }} type={nodeType.toLowerCase() === "float"
                                                                || nodeType.toLowerCase() === "integer" || nodeType.toLowerCase() === "number" ? "number" : "text"}
                                                                name="value" placeholder="Enter New Value" value={value} onChange={handleInputChange} />
                                                            {(nodeid.value.namespace.includes("Pressure")) &&
                                                                <font style={{ marginLeft: "5px" }} size="2" >{i18next.t('Unit_Pressure')} </font>}

                                                            {(nodeid.value.namespace.includes("Temperature")) &&
                                                                <font style={{ marginLeft: "5px" }} size="2" >{i18next.t('Unit_Temperature')} </font>}

                                                            {(nodeid.value.namespace.includes("TireWidth")) &&
                                                                <font style={{ marginLeft: "5px" }} size="2" >{i18next.t('mm')} </font>}


                                                        </div>
                                                    </>
                                                )}

                                            {nodeType.toLocaleLowerCase() === "date" && (


                                                <DatePicker
                                                    selected={value && new Date(value)}
                                                    className='inputDate'
                                                    onChange={handleInputChange}
                                                    //dateFormat="yyyy-MM-dd"
                                                    dateFormat="dd.MM.yyyy"
                                                    calendarStartDay={1}

                                                />
                                            )}

                                            {nodeType.toLowerCase() === "bool" && (
                                                <>
                                                    <div className="row" style={{}}>
                                                        <div>
                                                            <input
                                                                className="radio"
                                                                type="checkbox"
                                                                id="yes"
                                                                name="check"
                                                                value={"true"}
                                                                checked={value === "true"}
                                                                onChange={handleInputChangeBool}
                                                            />
                                                            {/* <label style={{marginLeft: "5px"}} htmlFor={"yes"}> Yes</label>*/}
                                                        </div>
                                                    </div>
                                                </>

                                            )}
                                        </div>
                                    </>
                                )}
                                <div>
                                    <Tooltip placement="right" overlay={ButtonEnable ? i18next.t('errorbutton') : ""}>
                                        <Button onClick={() => setMeasurement()} style={{ marginTop: "10px" }} type="primary" disabled={ButtonEnable}>
                                            {i18next.t('Update')}
                                        </Button>
                                    </Tooltip>
                                    {isLoading &&
                                        <>
                                            <div className="loading" style={{ position: "absolute" }}>
                                                <div className="loader"></div>
                                                <div className="text">{i18next.t('Loading')}...</div>

                                            </div>
                                        </>}
                                </div>
                            </div>
                            <div className="form-group col-md-6">

                                {deviceInfo && (
                                    <>
                                        <div className={window.innerWidth < 992 ? "form-group col-md-9" : "form-group col-md-6"}>
                                            <Container style={{ padding: "15px" }} className="card" fluid>
                                                <Row>
                                                    <font size="5" style={{ textAlign: "center" }}>{i18next.t('Deviceinfo')}</font>
                                                    <hr
                                                        style={{
                                                            background: "#545454",
                                                            height: "3px",
                                                            border: "none",
                                                            margin: "3"
                                                        }}
                                                    />
                                                </Row>
                                                <Row>
                                                    <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                        <font size="3" style={{}}><b>{i18next.t('Id')}:</b></font></Col>
                                                    <Col>
                                                        <font size="3" style={{}}>{deviceInfo.id}</font>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                        <font size="3" style={{}}><b>{i18next.t('Name')}:</b></font></Col>
                                                    <Col>
                                                        <font size="3" style={{}}>{deviceInfo.name}</font>
                                                    </Col>
                                                </Row>


                                                <Row>
                                                    <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                        <font size="3" style={{}}><b>{i18next.t('Description')}:</b></font></Col>
                                                    <Col>
                                                        <font size="3" style={{}}>{deviceInfo.description}</font>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                        <font size="3" style={{}}><b>{i18next.t('ClientPrefix')}:</b></font></Col>
                                                    <Col>
                                                        <font size="3" style={{}}>{deviceInfo.clientPrefix}</font>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>

                                    </>
                                )}
                                <br />
                                {nodeid && (
                                    <>
                                        <Container style={{ padding: "15px" }} className="card" fluid>
                                            <Row>
                                                <font size="5" style={{ textAlign: "center" }}>{i18next.t('Namespaceinfo')}</font>
                                                <hr
                                                    style={{
                                                        background: "#545454",
                                                        height: "3px",
                                                        border: "none",
                                                        margin: "3"
                                                    }}
                                                />
                                            </Row>
                                            <Row>
                                                <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                    <font size="3" style={{}}><b>{i18next.t('Name')}:</b></font></Col>

                                                <Col>
                                                    <font size="3" style={{}}>{nodeInfo.name}</font>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                    <font size="3" style={{}}><b>{i18next.t('Description')}:</b></font></Col>
                                                <Col>
                                                    <font size="3" style={{}}>{nodeInfo.description}</font>
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                    <font size="3" style={{}}><b>{i18next.t('Unit')}:</b></font></Col>
                                                <Col>
                                                    <font size="3" style={{}}>{nodeInfo.unit}</font>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                    <font size="3" style={{}}><b>{i18next.t('Type')}:</b></font></Col>
                                                <Col>
                                                    <font size="3" style={{}}>{nodeInfo.contentType}</font>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                    <font size="3" style={{}}><b>{i18next.t('ClientPrefix')}:</b></font></Col>
                                                <Col>
                                                    <font size="3" style={{}}>{nodeInfo.clientPrefix}</font>
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                    <font size="3" style={{}}><b>{i18next.t('Namespace')}:</b></font></Col>
                                                <Col>
                                                    <font size="3" style={{}}>{nodeInfo.namespace}</font>
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col xs={3} style={{ flex: "0 0 150px" }}>
                                                    <font size="3" style={{}}><b>{i18next.t('PublicAvailable')}:</b></font></Col>
                                                <Col>
                                                    <font size="3" style={{}}>{nodeInfo.publicAvailable ? "Yes" : "No"}</font>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};
export default Measurement;