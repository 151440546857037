/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich gesch├╝tzt.
 */
import React, { useEffect, useState } from "react";
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
}
    from 'mdb-react-ui-kit';
import i18next from "i18next";
import { TagsInput } from "react-tag-input-component";
import { Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { clientPrefixUserUpdateApi } from "../../hooks/clientPrefixApi";
import { clientPrefixUserApi } from '../../hooks/clientPrefixApi';
import { Loading } from "../../utils/loading";
import useWindowSize from "../../utils/useWindowSize";



/**
 * This component is for the user to update his personal informations
 *  
 * @author hatem sfar
 *
 */
const Security = () => {
    const [grantAccessTags, setGrantAccessTags] = useState([])
    const [emailActive, setEmailActive] = useState(false);
    const [smsActive, setSmsActive] = useState(false);
    const [mute, setMute] = useState(false);
    const [clientActive, setClientActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const windowSize = useWindowSize();


    /**
     * 
     */
    useEffect(() => {
        getClient()
        //eslint-disable-next-line
    }, []);

    /**
     * 
     */
    const getClient = () => {
        try {
            clientPrefixUserApi()
                .then((data) => {
                    const timer = setTimeout(() => {
                        setGrantAccessTags(data.grantAccess || [])
                        setEmailActive(data.reportActive || false);
                        setSmsActive(data.smsActive || false);
                        setMute(data.mute || false);
                        setClientActive(data.active || false);
                        setIsLoading(false)
                    }, 100); // Adjust the delay time as needed
                    return () => clearTimeout(timer);
                })
        } catch (err) {
            if (err.response && err.response.status === 504) {
                console.error("Gateway Time-out: The server took too long to respond.");
            }
            console.log(err);
        }
    };



    /**
    * Update client object with new values
    */
    const updateClient = (client) => {
        try {
            clientPrefixUserUpdateApi(client)
                .then((data) => {
                    console.log("updated")
                })
        } catch (err) {
            if (err.response && err.response.status === 504) {
                console.error("Gateway Time-out: The server took too long to respond.");
            }
            console.log(err);
        }
    }

    /**
     * 
     * @param {*} newTags 
     */
    const handleGrantTagsChange = (newTags) => {
        const grantAccessContent = newTags.length === 0 ? ["undefined"] : newTags;
        grantAccessContent[0] !== "undefined" && setGrantAccessTags(grantAccessContent);
        const updatedClient = {
            grantAccess: grantAccessContent,
            emailActive: emailActive,
            smsActive: smsActive,
            mute: mute,
            clientActive: clientActive
        };
        grantAccessTags !== newTags && updateClient(updatedClient);
    }


    return (
        <>
            {isLoading && <Loading />}
            <MDBContainer fluid style={{ paddingLeft: '0', paddingRight: '0', marginTop: '-50px' }}>
                <MDBRow className='d-flex justify-content-center align-items-center'>
                    <MDBCol lg='8' style={{ width: '100%' }}>
                        <MDBCard className='my-5 rounded-3' style={{ border: 'none' }}>
                            <MDBCardBody className={windowSize[0] > 991 && 'px-5'} style={{ marginTop: '0px !important' }}>
                                <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">
                                    {i18next.t('Security')} </h3>
                                <MDBRow>
                                    <MDBCol md='10'>
                                        <h6>{i18next.t('GrantAccess')}
                                            <Tooltip
                                                placement="top"
                                                title={i18next.t('GrantAccess_tooltip')}
                                            >
                                                <font
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    size="3" >
                                                    {" "} <HelpIcon style={{ zoom: '0.6' }} />
                                                </font>
                                            </Tooltip>
                                        </h6>
                                        <TagsInput value={grantAccessTags} onChange={handleGrantTagsChange} name="fruits" placeHolder={i18next.t('enterGrantAccess')} />
                                        <em style={{ fontSize: '13px' }}>{i18next.t("msgTagGrantAccess")} </em>
                                    </MDBCol>
                                </MDBRow>
                                <br /><br />
                                {/*<Button style={{ backgroundColor: '#8c8cf0', color: "white", border: "1px solid #0d6efd" }} className='mb-4' size='large'>{i18next.t('Update')} </Button>*/}

                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </>
    );
};
export default Security;
