/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschÃ¼tzt.
 */

import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import i18next from 'i18next';
import { iconAlertWarning } from '../../utils/iconAlert.js';
import { Loading } from '../../utils/loading.js';
import { getLastMeasurementApi } from '../../hooks/measurementApi.js';
import TimeOutToast from '../../utils/TimeOutToast.js';

/**
 * This component represents the the chart of the Pressure and Temperatur
 * 
 * @author hatem sfar
 *
 */
function TireChart({ tire, device }) {


    const [isLoading, setIsLoading] = useState(true);
    const [allMeasurements, setAllMeasurements] = useState([]); // new state variable


    //    const [pressureData, setPressureData] = useState([]);
    //  const [tempData, setTempData] = useState([]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const timer = setTimeout(() => {
                    getLastMeasurement();
                }, 2000);

                return () => clearTimeout(timer);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, []);


    /**
      * to get the last measurements ( Pressure and Temperatur) for
      * last 3hours
      */
    const getLastMeasurement = async () => {
        const axle = tire.axle;
        let pageNumber = 1;
        let nextPage = true;
        const namespace = `de.okit.automotive.tpms.axle.${axle}.${tire.direction}.${tire.position}.*`;
        const allMeasurements = [];


        while (nextPage) {
            try {
                const response = await getLastMeasurementApi(device.id, namespace, pageNumber);

                response?.data.measurements.forEach((item) => {
                    const namespaceArr = item.namespace.split(".");
                    const type = namespaceArr[namespaceArr.length - 1];

                    item.measurements.forEach((measurement) => {
                        const measurementDate = new Date(measurement.measurementdate);
                        const formattedTime = measurementDate.toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false
                        });

                        allMeasurements.push({
                            [type]: measurement.content,
                            Time: formattedTime,
                        });
                    });
                });

                setAllMeasurements(allMeasurements)
                pageNumber++;

                if (!response.data.nextPage) {
                    nextPage = false;
                }

            } catch (error) {
                if (error.response && error.response.status === 504) {
                    console.error("Gateway Time-out: The server took too long to respond.");
                    TimeOutToast()
                    setIsLoading(false)
                }
                //console.error("Error fetching data:", error);
                nextPage = false;
                setIsLoading(false);
            }
        }
        setIsLoading(false);


    };




    const colorPressure = '#008FFB';
    const colorTemp = '#008800';
    const colorWarning = '#FEB019';
    const colorAlarm = '#FE3E19';
    const colorIdeal = '#000000'

    const pressureData = allMeasurements.filter((item) => item.Pressure !== undefined);
    const tempData = allMeasurements.filter((item) => item.Temperature !== undefined);

    const pressureTimes = pressureData.map((item) => item.Time).reverse();
    const tempTimes = tempData.map((item) => item.Time).reverse();

    const combinedTimes = Array.from(new Set([...tempTimes, ...pressureTimes]));

    const state = {
        series: [
            {
                name: i18next.t('Pressure'),
                type: 'line',
                data: pressureData.map((item) => parseFloat(item.Pressure)).reverse(),
                color: colorPressure
            },
            {
                name: i18next.t('Temperature'),
                type: 'line',
                data: tempData.map((item) => parseFloat(item.Temperature)).reverse(),
                color: colorTemp
            }

        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false
                }

            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [2, 2, 1, 1, 1, 1]
            },
            title: {
                text: 'Temperatur und Reifendruck Entwicklung',
                align: 'left',
                offsetX: 0
            },
            xaxis: {
                categories: combinedTimes,
                tickAmount: 10
            },
            yaxis: [
                {
                    seriesName: i18next.t('Pressure'),
                    min: 0,
                    max: 15,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: colorPressure
                    },
                    labels: {
                        style: {
                            colors: colorPressure,
                        }
                    },
                    title: {
                        text: i18next.t('PressureUnit'),
                        style: {
                            color: colorPressure,
                        }
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                {
                    seriesName: i18next.t('Temperature'),
                    min: -20,
                    max: 100,
                    opposite: true,
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: true,
                        color: colorTemp
                    },
                    labels: {
                        style: {
                            colors: colorTemp,
                        }
                    },
                    title: {
                        text: i18next.t('TemperatureUnit'),
                        style: {
                            color: colorTemp,
                        }
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            ],
            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'topLeft',
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40,
                showForNullSeries: false,
                showForZeroSeries: false,
            },
            annotations: {
                yaxis: [{
                    y: tire.PressureLowerWarn,
                    y2: tire.PressureLowerAlarm,
                    borderColor: colorWarning,
                    fillColor: colorWarning,

                },
                {
                    y: tire.PressureUpperWarn,
                    y2: tire.PressureUpperAlarm,
                    borderColor: colorWarning,
                    fillColor: colorWarning,

                }, {
                    y: tire.PressureUpperAlarm,
                    y2: 15,
                    borderColor: colorAlarm,
                    fillColor: colorAlarm,

                },
                {
                    y: tire.PressureLowerAlarm,
                    y2: 0,
                    borderColor: colorAlarm,
                    fillColor: colorAlarm,
                },
                {
                    y: tire.PressureIdeal,
                    borderColor: colorIdeal,
                }]
            }


        }
    };


    if (isLoading) {
        return (<Loading pos="absolute" />);
    }

    if (!isLoading && allMeasurements.length < 1) {
        return (
            <div style={{ marginTop: "20px" }} className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <img src={iconAlertWarning} height="30" className="d-inline-block" alt="warning sign" />
                    <p className="fs-3">
                        <span className="text-danger"></span>
                        {i18next.t('noDataChart')}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <>
            <ReactApexChart options={state?.options} series={state?.series} type="line" height={350} />
        </>
    )
}

export default TireChart;