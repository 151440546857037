/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import LangDropDown from "../translation/LangDropDown";
import i18next from "i18next";
import ClientPrefixDropDown from '../ClientPrefixDropDown';
import auth from "../../auth/auth";
import ROLES from "../../utils/roles";
import list from '../../assets/images/list.svg';
import ClientUserDropDown from '../ClientUserDropDown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';


/**
 * The top navigation bar component for desktop.
 * 
 * @author hatem sfar
 *
 */
function NavBarDesktop() {

    return (
        <>


            <Form className="d-flex form_style">
                <div id='title_powerd' >
                    <font size="3" color="white">Powered by OKIT</font>
                </div>
                {auth([ROLES.OPERATOR]) ? <ClientPrefixDropDown /> : <ClientUserDropDown />}

                <LangDropDown />
                <Dropdown>
                    <Dropdown.Toggle id="dropdown_toggle">
                        <img
                            src={list}
                            width="fit-content"
                            height="30"
                            className="d-inline-block align-top"
                            alt="okit.vcm logo"
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu id='dropdown_menu'>
                        <Dropdown.Item href="/devices"><MapOutlinedIcon style={{ zoom: '0.8' }} />{i18next.t('Map')} </Dropdown.Item>
                        {auth([ROLES.ADMIN, ROLES.OPERATOR]) && <Dropdown.Item href="/measurement"><StraightenOutlinedIcon style={{ zoom: '0.8' }} />{i18next.t('WebMapApi')} </Dropdown.Item>}
                        <Dropdown.Item href="/user/settings"><SettingsOutlinedIcon style={{ zoom: '0.8' }} />{i18next.t('Settings')} </Dropdown.Item>
                        <Dropdown.Item href="/logout"><LogoutOutlinedIcon style={{ zoom: '0.8' }} />{i18next.t('Logout')} </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

            </Form>

        </>
    );
}

export default NavBarDesktop;