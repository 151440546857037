/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import axios from "axios";
import apiList from "../utils/apiClient.js";

/**
 * This component implements the api's call
 * 
 * @author hatem sfar
 *
 */
const getMeasurementByNameSpaceApi = async (id, nodeid) => {
    const { data } = await axios.get(apiList.MeasurementNameSpaces + id, {
        params: {
            position: nodeid
        },
    });
    return data;
}

const setMeasurementByNameSpaceApi = async (id, value, nodeid) => {
    const { data } = await axios.post(apiList.SetMeasurement + id, value, {
        params: {
            obj: value,
            namespace: nodeid
        }
    });
    return data;
}

const getLastMeasurementApi = async (id, namespace, pageNumber) => {
    const { data } = await axios.get(apiList.LastMeasurement + id, {
        params: {
            position: namespace,
            pageNumber: pageNumber
        },
    });
    return data;
}

const getIntervallMeasurementApi = async (id, namespace, intervalStart, intervalEnd, pageNumber) => {
    const { data } = await axios.get(apiList.IntervalMeasurement + id, {
        params: {
            namespace: namespace,
            intervalStart: intervalStart,
            intervalEnd: intervalEnd,
            pageNumber: pageNumber

        },
    });
    return data;
}
const getHistoryMeasurementApi = async (id, namespace, intervalStart, intervalEnd, pageNumber) => {
    const { data } = await axios.get(apiList.HistoryMeasurement + id, {
        params: {
            namespace: namespace,
            intervalStart: intervalStart,
            intervalEnd: intervalEnd,
            pageNumber: pageNumber

        },
    });
    return data;
}

const getTiresMeasurementApi = async (id) => {
    const { data } = await axios.get(apiList.MeasurementTires + id);
    return data;
}


const getMeasurementRangeApi = async (LatCenter, LongCenter, raduis, pageNumber) => {
    const { data } = await axios.get(apiList.MeasurementRange, {
        params: {
            LatCenter: LatCenter,
            LongCenter: LongCenter,
            raduis: raduis,
            pageNumber: pageNumber

        },
    });

    return data;
}


const CheckTokenMeasurementApi = async (deviceList) => {
    const { data } = await axios.get(apiList.CheckTokenMeasurement, {
        params: {
            deviceList: deviceList,
        },
    });
    return data;
}

export {
    getMeasurementByNameSpaceApi,
    setMeasurementByNameSpaceApi,
    getLastMeasurementApi,
    getTiresMeasurementApi,
    getIntervallMeasurementApi,
    getMeasurementRangeApi,
    CheckTokenMeasurementApi,
    getHistoryMeasurementApi
}
