/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useRef, useState } from "react";
import analytic from "../../assets/images/analytic.svg";
import Select from "react-select";
import i18next from "i18next";
import DatePicker from "react-datepicker";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import L from "leaflet";
import { toast } from "react-toastify";
import "../../assets/css/analyticMap.css";
import "../../assets/css/react-datepicker.css";
import useWindowSize from "../../utils/useWindowSize";
import {
  getCoordinatesIntervallApi,
  getTourDataApi,
} from "../../hooks/coordinatesApi";
//eslint-disable-next-line
import {
  addBreaksMarkers,
  addMarkers,
  addPolylines,
  addTopSpeedsMarkers,
  convertDateToISOWithOffset,
  getNamespaceList,
  geteventdetails,
} from "./FctAnalyticMap";
import { MapEvents, loadMoreData } from "./FctAnalytic_2";
import TimeOutToast from "../../utils/TimeOutToast";
import { iconloadingtuordata } from "../../utils/iconStandard";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingAnalytic } from "../../utils/loadingAnalytic";

/**
 * This component represents AnalyticMap
 *
 * @author hatem sfar
 *
 */
const AnalyticMap = ({
  devices,
  mapRef,
  setAnalytic,
  isAnalytic,
  isRadarActive,
  setIsRadarActive,
  shadowMarkerRefs,
  setShowSpeedometer,
  focusDevice,
  isTraceActive,
  setIsTraceActive,
  isBreakActive,
  isTopSpeedActive,
  setIsBreakActive,
  setIsTopSpeedActive,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(
    localStorage.getItem("sidebar") || false
  );
  const [sidebarVisible, setSidebarVisible] = useState(isSidebarOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedDevice, setSelectedDevice] = useState();
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [nodeid, setNodeid] = useState(null);
  const [nodes, setNodes] = useState([]);
  const nodeOptions =
    nodes &&
    nodes?.map((node) => ({
      value: node.node,
      label: node.node.namespace,
    }));
  const deviceOptions =
    devices &&
    devices?.map((device) => ({
      value: device,
      label: device.name,
    }));
  const teenSpeed = 10;
  const twentySpeed = 20;
  const fifteenSpeed = 15;
  const thirtySpeed = 30;
  const fortySpeed = 40;
  const seventyfiveSpeed = 75;
  const fiftySpeed = 50;
  const onehundredtenSpeed = 110;
  const LIMITE = 10;

  const speedOptions = [
    { value: twentySpeed, label: teenSpeed + " - " + twentySpeed + " km/h" },
    { value: thirtySpeed, label: fifteenSpeed + " - " + thirtySpeed + " km/h" },
    { value: fortySpeed, label: twentySpeed + " - " + fortySpeed + " km/h" },
    {
      value: seventyfiveSpeed,
      label: fortySpeed + " - " + seventyfiveSpeed + " km/h",
    },
    {
      value: onehundredtenSpeed,
      label: fiftySpeed + " - " + onehundredtenSpeed + " km/h",
    },
  ];
  const [speedLimite, setSpeeLimite] = useState(speedOptions[2]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const windowSize = useWindowSize();
  let cancelTokenSource;
  const [allCoordinates, setAllCoordinates] = useState([]);
  let coordinatesByDevice = {};
  //eslint-disable-next-line
  let markerGroup = L.layerGroup();
  const polylineGroup = L.featureGroup();
  const polylineGroup_2 = L.featureGroup();
  let measurements = [];
  const [zoomLevel, setZoomLevel] = useState(13);
  const [boundsMap, setBoundsMap] = useState();
  const [drawnCoordinates, setDrawnCoordinates] = useState(new Set());
  const [tourData, setTourData] = useState([]);
  const [isTourData, setIsTourData] = useState(false);
  const [addrStarted, setAddStarted] = useState();
  const [addrEnded, setAddEnded] = useState();
  const [isLoadingTourData, setIsLoadingTourData] = useState(false);
  //eslint-disable-next-line
  const [isPolylineDone, setIsPolylineDone] = useState(false);
  const [allCoordinateSaved, setAllCoordinateSaved] = useState([]);
  const isDrawingEnabled = useRef(true);

  /**
   * for hide/show trace
   */
  useEffect(() => {
    if (isTraceActive && allCoordinateSaved.length > 0) {
      if (mapRef.current) {
        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Polyline) {
            mapRef.current.removeLayer(layer);
          }
        });
      }
      addPolylines(
        allCoordinateSaved,
        speedLimite,
        LIMITE,
        polylineGroup,
        setAllCoordinates
      );
      polylineGroup?.addTo(mapRef?.current);
    } else {
      if (mapRef.current) {
        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Polyline) {
            mapRef.current.removeLayer(layer);
          }
        });
      }
    }
    //eslint-disable-next-line
  }, [isTraceActive]);

  /**
   * for hide/show breaks
   */
  useEffect(() => {
    if (isBreakActive && allCoordinateSaved.length > 0) {
      addBreaksMarkers(tourData?.breaks, mapRef);
    } else {
      if (mapRef.current) {
        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker && layer.options.isBreak) {
            mapRef.current.removeLayer(layer);
          }
        });
      }
    }
    //eslint-disable-next-line
  }, [isBreakActive]);

  /**
   * for hide/show topSpeeds
   */
  useEffect(() => {
    if (isTopSpeedActive && allCoordinateSaved.length > 0) {
      addTopSpeedsMarkers(tourData?.topSpeeds, mapRef, polylineGroup);
    } else {
      if (mapRef.current) {
        mapRef.current.eachLayer((layer) => {
          if (
            (layer instanceof L.Marker && layer.options.isTopSpeed) ||
            (layer instanceof L.Polyline && layer.options.isTopSpeedLine)
          ) {
            mapRef.current.removeLayer(layer);
          }
        });
      }
    }
    //eslint-disable-next-line
  }, [isTopSpeedActive]);

  /**
   *
   */
  useEffect(() => {
    if (allCoordinateSaved.length > 0 && isTourData && tourData.deviceName) {
      // console.log("tourData: ", tourData)
      let endText = "";
      let endTextTime = "";
      const lastCoordinate = allCoordinateSaved[allCoordinateSaved.length - 1];

      if (lastCoordinate?.speed <= 1) {
        endText = i18next.t("endTextPopUp");
        endTextTime = i18next.t("endTextTime");
      } else {
        endText = i18next.t("onWayTextend");
        endTextTime = i18next.t("onWayTextTimeend");
      }

      setTimeout(() => {
        allCoordinateSaved.length > 0 &&
          axios
            .get(
              `${process.env.REACT_APP_OSM_API}lat=${lastCoordinate?.latitude}&lon=${lastCoordinate?.longitude}&zoom=18&addressdetails=2&format=json`
            )
            .then((response) => {
              const location_end =
                response.data.address.road +
                ", " +
                response.data.address.village +
                ", " +
                response.data.address.postcode;
              addMarkers(
                lastCoordinate,
                lastCoordinate,
                mapRef,
                "end",
                location_end,
                endText,
                endTextTime,
                tourData
              );
            });
      }, 1000);
    }
    //eslint-disable-next-line
  }, [allCoordinateSaved, isTourData, tourData]);

  /**
   *
   */
  useEffect(() => {
    selectedDevice && getNamespaceList(selectedDevice, setNodes, setNodeid);
    //eslint-disable-next-line
  }, [selectedDevice]);

  /**
   *
   */
  useEffect(() => {
    handleMoreDetails();
    //eslint-disable-next-line
  }, [zoomLevel, boundsMap]);

  /**
   *
   */
  //eslint-disable-next-line
  const handleMoreDetails = () => {
    if (isAnalytic && zoomLevel >= 16) {
      const currentDate = new Date();
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
      let endDateToCheck = dateEnd ? new Date(dateEnd) : currentDate;
      // Check if the difference between startDate and endDate is more than one day
      if (endDateToCheck - new Date(dateStart) > oneDayInMilliseconds) {
        loadMoreData(
          boundsMap,
          selectedDevice,
          dateStart,
          dateEnd,
          speedLimite,
          LIMITE,
          mapRef,
          polylineGroup_2,
          drawnCoordinates,
          setIsPolylineDone,
          setIsLoading
        );
      }
    }
  };
  /*
    useEffect(() => {
        if (isPolylineDone) {
            toast.info(i18next.t('polyline_done'), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
                theme: 'colored',
                style: {
                    top: '50px',
                },
            });
            setIsPolylineDone(false)
        }
        // eslint-disable-next-line
    }, [isPolylineDone]);*/

  /**
   *
   */
  useEffect(() => {
    if (isAnalytic && isRadarActive) {
      setIsRadarActive(false);
      Object.values(shadowMarkerRefs.current).forEach((circleMarker) => {
        mapRef.current.removeLayer(circleMarker);
      });
      if (mapRef.current) {
        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker && layer.options.isMeasurementMarker) {
            mapRef.current.removeLayer(layer);
          }
        });
      }
    }
    isAnalytic &&
      nodeid &&
      geteventdetails(
        dateStart,
        dateEnd,
        selectedDevice,
        nodeid,
        mapRef,
        allCoordinates,
        measurements,
        markerGroup
      );
    isAnalytic && setShowSpeedometer(false);
    // eslint-disable-next-line
  }, [nodeid, allCoordinates, isAnalytic]);

  /**
   * Reset the drawnCoordinates to an empty set.
   */
  const resetDrawnCoordinates = () => {
    setDrawnCoordinates(new Set());
  };

  /**
   *
   */
  const handleOpenAnalyse = () => {
    setSidebarOpen(true);
    setSidebarVisible(true);
    setAnalytic(true);
    localStorage.setItem("sidebar", true);
  };

  /**
   *
   */
  const handleCloseAnalyse = () => {
    setSidebarOpen(false);
    localStorage.removeItem("sidebar");
    setAnalytic(false);
    if (focusDevice) {
      setShowSpeedometer(true);
    }
    // Remove previous polylines
    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Polyline || layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });
    }
    setTimeout(() => {
      setSidebarVisible(false);
    }, 300); // Adjust the delay time to match the animation duration
  };

  /**
   *
   * @param {*} data
   */
  function handleSelect(data) {
    setSelectedDevice(data);
  }

  /**
   * Method to handle speed selection
   * @param {*} selectedOption
   */
  const handleSelectSpeed = (selectedOption) => {
    setSpeeLimite(selectedOption);
    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          mapRef.current.removeLayer(layer);
        }
      });
    }
    addPolylines(
      allCoordinateSaved,
      selectedOption,
      LIMITE,
      polylineGroup,
      setAllCoordinates
    );
    polylineGroup.addTo(mapRef.current);
  };

  /**
   *
   * @param {*} event
   */
  const handleNode = (event) => {
    if (!event) {
      setNodeid("");
    } else {
      setNodeid(event);
    }
  };

  /**
   *
   */
  const clearInput = () => {
    setDateEnd(null);
  };

  /**
   *
   */
  const resetAnalyse = () => {
    // Cancel the ongoing request if cancelTokenSource is available
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled by user");
    }

    //setSelectedDevice(null);
    //setDateStart('');
    //setDateEnd('');
    isDrawingEnabled.current = false;
    setIsLoading(false);
    setIsSubmitted(false);
    /*if (mapRef.current) {
            mapRef.current.eachLayer((layer) => {
                if (layer instanceof L.Polyline || layer instanceof L.Marker) {
                    mapRef.current.removeLayer(layer);
                }
            });
        }*/
  };

  /**
   *
   */
  const handleCloseSideBarMobile = () => {
    setSidebarOpen(false);
    setTimeout(() => {
      setSidebarVisible(false);
    }, 300);
  };

  /**
   *
   * @returns
   */
  const errorCheck = () => {
    const errors = {};

    if (!selectedDevice) {
      errors.selectedDevice = i18next.t("selecDevice");
    }

    if (!dateStart) {
      errors.dateStart = i18next.t("selecStartDate");
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return errors;
    }

    setValidationErrors({});
  };

  /**
   *
   * @returns
   */
  const handleApplyAnalyse = async () => {
    resetDrawnCoordinates();
    setIsTraceActive(true);
    setIsBreakActive(false);
    setIsTopSpeedActive(false);
    // check the error
    const error = errorCheck();
    if (error) {
      return null;
    }

    // call api
    callApi();

    // Get tour data
    getTourData();

    // must close the analytic sidebar on mobile version
    if (window.innerWidth < 991) {
      handleCloseSideBarMobile();
    }
  };

  /**
   *
   */
  const callApi = async () => {
    setIsLoading(true);
    setIsTourData(false);
    setAllCoordinateSaved([]);
    setIsSubmitted(true);
    isDrawingEnabled.current = true;
    let allCoordinatePolyines = [];
    // Create a new CancelToken source
    cancelTokenSource = axios.CancelToken.source();
    // convert date
    let isoDateStart = "";
    let isoDateEnd = "";
    let pageNumber = 1;
    let nextPage = true;

    isoDateStart = convertDateToISOWithOffset(dateStart);
    if (dateEnd) {
      isoDateEnd = convertDateToISOWithOffset(dateEnd);
    } else {
      isoDateEnd = null;
    }

    // Remove previous polylines and markers
    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Polyline || layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });
    }

    let firstMarkerAdded = false;

    while (nextPage) {
      let response;
      try {
        response = await getCoordinatesIntervallApi(
          selectedDevice.value,
          isoDateStart,
          isoDateEnd,
          cancelTokenSource.token,
          pageNumber
        );

        const coordinateData = response.data.result.coordinates;
        allCoordinatePolyines = allCoordinatePolyines.concat(coordinateData);
        if (isDrawingEnabled.current) {
          // Add the group of polylines and markers to the map
          addPolylines(
            coordinateData,
            speedLimite,
            LIMITE,
            polylineGroup,
            setAllCoordinates
          );

          // Group polylines to map
          polylineGroup.addTo(mapRef.current);

          // Set the view to fit the bounds of the polyline group
          mapRef.current.fitBounds(polylineGroup.getBounds(), { maxZoom: 15 });
        }

        // Check if it's the first page and markers are not added yet
        if (response.isFirstPage && !firstMarkerAdded) {
          const firstCoordinate = coordinateData[0];
          let startText = "";
          let startTextTime = "";
          let coordinateMeasurment = null;

          if (firstCoordinate.speed <= 1) {
            startText = i18next.t("startTextPopUp");
            startTextTime = i18next.t("startTextTime");
            // Keep checking subsequent coordinates until finding one with speed greater than 1
            for (let i = 1; i < coordinateData.length; i++) {
              if (coordinateData[i].speed > 1) {
                coordinateMeasurment = coordinateData[i - 1];
                break;
              }
            }
          } else {
            startText = i18next.t("onWayText");
            startTextTime = i18next.t("onWayTextTime");
            coordinateMeasurment = firstCoordinate;
          }

          // If coordinateToSend is not null, add the marker
          if (coordinateMeasurment) {
            setTimeout(() => {
              axios
                .get(
                  `${process.env.REACT_APP_OSM_API}lat=${coordinateMeasurment.latitude}&lon=${coordinateMeasurment.longitude}&zoom=18&addressdetails=2&format=json`
                )
                .then((response) => {
                  const location_start =
                    response?.data?.address?.road +
                    ", " +
                    response?.data?.address?.village +
                    ", " +
                    response?.data?.address?.postcode;
                  addMarkers(
                    firstCoordinate,
                    coordinateMeasurment,
                    mapRef,
                    "start",
                    location_start,
                    startText,
                    startTextTime
                  );
                });
            }, 1000);
            firstMarkerAdded = true;
          }
        }

        // Check if it's the last page and markers are not added yet
        if (response.isLastPage) {
        }

        if (!response.data.result.nextPage) {
          nextPage = false;
        }
        pageNumber++;
      } catch (err) {
        nextPage = false;
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          nextPage = false;
          TimeOutToast();
          setIsLoading(false);
          setIsSubmitted(false);
        }
        setIsSubmitted(false);
        if (err?.response?.status === 404) {
          nextPage = false;
          if (Object.keys(coordinatesByDevice).length === 0) {
            setIsLoading(false);
            // Remove previous polylines and markers
            if (mapRef.current) {
              mapRef.current.eachLayer((layer) => {
                if (layer instanceof L.Polyline || layer instanceof L.Marker) {
                  mapRef.current.removeLayer(layer);
                }
              });
            }

            toast.info(i18next.t("noData_inTime"), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              theme: "colored",
              style: {
                top: windowSize[0] > 991 ? "40px" : "50px",
              },
            });

            break;
          }
        }

        if (axios.isCancel(err)) {
          // Request was canceled
        } else {
          //console.log(err);
        }
      }
    }
    setAllCoordinateSaved(allCoordinatePolyines);
    //setIsLoading(false);
    setIsSubmitted(false);
  };

  /**
   * get tour data
   */
  const getTourData = async () => {
    setIsLoadingTourData(true);
    let isoDateStart = "";
    let isoDateEnd = "";
    isoDateStart = convertDateToISOWithOffset(dateStart);
    if (dateEnd) {
      isoDateEnd = convertDateToISOWithOffset(dateEnd);
    } else {
      isoDateEnd = null;
    }

    const response = await getTourDataApi(
      selectedDevice.value,
      isoDateStart,
      isoDateEnd
    );
    const totalMinutes = parseInt(response.minutesBreaks);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    const minutesTotal = parseInt(response.minutesTotal);
    const hours_minutesTotal = Math.floor(minutesTotal / 60);
    const remainingMinutes_minutesTotal = minutesTotal % 60;

    response.minutesTotal = `${hours_minutesTotal}:${remainingMinutes_minutesTotal
      .toString()
      .padStart(2, "0")}`;
    response.minutesBreaks = `${hours}:${remainingMinutes
      .toString()
      .padStart(2, "0")}`;
    if (isDrawingEnabled.current) {
      setTourData(response);
    }

    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_OSM_API}lat=${response.latitudeStart}&lon=${response.longitudeStart}&zoom=18&addressdetails=2&format=json`
        )
        .then((response) => {
          setAddStarted(response.data.display_name);
        });
    }, 1000);

    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_OSM_API}lat=${response.latitudeEnd}&lon=${response.longitudeEnd}&zoom=18&addressdetails=2&format=json`
        )
        .then((response) => {
          setAddEnded(response.data.display_name);
        });
    }, 1000);

    setTimeout(() => {
      setIsLoadingTourData(false);
      setIsTourData(true);
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      {isAnalytic && (
        <>
          <MapEvents
            mapRef={mapRef}
            setZoomLevel={setZoomLevel}
            setBoundsMap={setBoundsMap}
          />
        </>
      )}
      {isLoading && <LoadingAnalytic />}

      <div
        style={{ display: isAnalytic ? "none" : "block" }}
        className="analytic_div1"
      >
        <img
          src={analytic}
          onClick={handleOpenAnalyse}
          height="20"
          className="d-inline-block"
          alt="analytic"
        />
      </div>

      {sidebarVisible && (
        <>
          <div
            style={{
              float: "right",
              overflowY: "scroll",
              width: windowSize[0] < 991 && "100%",
              marginTop: windowSize[0] < 991 && "0px",
              zIndex: 9999,
              height: "100%",
            }}
          >
            <div
              style={{
                float: "right",
                padding: "5px",
                width: windowSize[0] < 991 ? "100%" : null,
                marginTop: windowSize[0] < 991 ? "0px" : null,
              }}
              className={`sidebar_analytic ${
                isSidebarOpen ? "" : "removeSideBar_analytic"
              }`}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  className="sidebar_header"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: windowSize[0] > 991 ? "320px" : "100%",
                  }}
                >
                  <ArrowBackIosNewIcon
                    onClick={handleCloseAnalyse}
                    style={{
                      marginRight: "15px",
                      cursor: "pointer",
                      color: "gray",
                      rotate: "180deg",
                    }}
                  />
                  <h4
                    style={{
                      color: "rgb(140, 140, 240)",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {i18next.t("Analytics")}{" "}
                  </h4>
                </div>

                <div
                  style={{ width: windowSize[0] > 991 ? "320px" : "100%" }}
                  className="dropdown-container"
                >
                  <label style={{ float: "left" }}>
                    {i18next.t("Device")} :
                  </label>
                  <br />
                  <Select
                    options={deviceOptions}
                    value={selectedDevice}
                    onChange={handleSelect}
                    isSearchable={true}
                    //isMulti
                    className={
                      validationErrors.selectedDevice && selectedDevice == null
                        ? "input_error"
                        : ""
                    }
                    placeholder={i18next.t("Choose_device")}
                  />

                  {validationErrors.selectedDevice &&
                    selectedDevice == null && (
                      <div style={{ color: "red" }}>
                        {validationErrors.selectedDevice}
                      </div>
                    )}
                </div>
                <br />

                <div
                  style={{ width: windowSize[0] > 991 ? "320px" : "100%" }}
                  className="date-time-container"
                >
                  <label style={{ float: "left" }}>
                    {i18next.t("Interval_Start")} :
                  </label>
                  <br />

                  <DatePicker
                    selected={dateStart}
                    onChange={(date) => setDateStart(date)}
                    showTimeSelect
                    dateFormat="HH:mm dd-MM-yyyy"
                    timeFormat="HH:mm"
                    className={
                      validationErrors.dateStart && dateStart === ""
                        ? "input_error2"
                        : "inputDate2"
                    }
                    timeIntervals={15}
                    placeholderText="HH:mm dd-MM-yyyy"
                    calendarStartDay={1}
                  />
                  {validationErrors.dateStart && dateStart === "" && (
                    <div style={{ color: "red" }}>
                      {validationErrors.dateStart}
                    </div>
                  )}
                </div>
                <br />

                <div
                  style={{ width: windowSize[0] > 991 ? "320px" : "100%" }}
                  className="date-time-container"
                >
                  <label style={{ float: "left" }}>
                    {i18next.t("Interval_End")} :
                  </label>
                  <br />
                  <div className="input-container">
                    <DatePicker
                      selected={dateEnd}
                      onChange={(date) => setDateEnd(date)}
                      showTimeSelect
                      dateFormat="HH:mm dd-MM-yyyy"
                      timeFormat="HH:mm"
                      className="inputDate2"
                      timeIntervals={15}
                      placeholderText="HH:mm dd-MM-yyyy"
                      calendarStartDay={1}
                    />
                    {dateEnd && (
                      <span
                        style={{
                          float: "right",
                          marginTop: "-30px",
                          position: "relative",
                          marginRight: "15px",
                          cursor: "pointer",
                          color: "gray",
                        }}
                        className="clear-icon"
                        onClick={clearInput}
                      >
                        x
                      </span>
                    )}
                  </div>
                </div>
                <br />

                <div
                  style={{ width: windowSize[0] > 991 ? "320px" : "100%" }}
                  className="dropdown-container"
                >
                  <label style={{ float: "left" }}>
                    {i18next.t("Speed_Limite")} :
                  </label>
                  <br />
                  <Select
                    options={speedOptions}
                    //selected={MAX_SPEED}
                    value={speedLimite}
                    onChange={handleSelectSpeed}
                  />
                </div>
                <br />

                <div
                  style={{ width: windowSize[0] > 991 ? "320px" : "100%" }}
                  className="dropdown-container"
                >
                  <label style={{ float: "left" }}>
                    {i18next.t("Namespace")}:
                  </label>
                  <br />
                  <Select
                    options={nodeOptions}
                    value={nodeid}
                    onChange={handleNode}
                    placeholder={i18next.t("SelectNamespace")}
                    isSearchable={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        textAlign: "left", // Align the input and value text to the left
                      }),
                      option: (provided) => ({
                        ...provided,
                        textAlign: "left", // Align the option text to the left
                      }),
                    }}
                  />
                </div>
                <br />

                <div
                  className="button-container"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: windowSize[0] > 991 ? "320px" : "100%",
                  }}
                >
                  <button
                    onClick={handleApplyAnalyse}
                    style={{ background: "rgb(140, 140, 240)" }}
                    className="btn btn-primary"
                    disabled={isSubmitted}
                  >
                    {isSubmitted ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      i18next.t("Analyse")
                    )}
                  </button>

                  <button onClick={resetAnalyse} className="btn btn-danger">
                    {" "}
                    {i18next.t("Abort")}{" "}
                  </button>
                </div>
                <br />
                <br />

                <div
                  style={{
                    width: windowSize[0] > 991 ? "320px" : "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center", display: "inline-block" }}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {i18next.t("Slow")}{" "}
                    </span>
                    <hr
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, rgb(255, 0, 0), rgb(0, 0, 255))",
                        width: "80px",
                        height: "5px",
                        border: "none",
                        opacity: 1,
                      }}
                    />
                  </div>
                  <div style={{ textAlign: "center", display: "inline-block" }}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {i18next.t("Moderate")}
                    </span>
                    <hr
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, rgb(0, 0, 255),rgb(0, 0, 255))",
                        width: "80px",
                        height: "5px",
                        border: "none",
                        opacity: 1,
                      }}
                    />
                  </div>
                  <div style={{ textAlign: "center", display: "inline-block" }}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {i18next.t("Fast")}
                    </span>
                    <hr
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, rgb(0, 0, 255),rgb(0, 128, 0))",
                        width: "80px",
                        height: "5px",
                        border: "none",
                        opacity: 1,
                      }}
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>

                <div>
                  {isLoadingTourData && (
                    <img
                      src={iconloadingtuordata}
                      height="40"
                      className="d-inline-block"
                      alt="iconloadingtuordata"
                    />
                  )}
                  <div>
                    {isTourData && !isLoadingTourData && (
                      <>
                        <Container fluid>
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              {" "}
                              <b>{i18next.t("startingLocation")}</b>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                              {" "}
                              {addrStarted}
                            </Col>
                          </Row>
                          <hr style={{ margin: "revert" }} />
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              {" "}
                              <b>{i18next.t("Ending_Location")}</b>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                              {" "}
                              {addrEnded}
                            </Col>
                          </Row>
                          <hr style={{ margin: "revert" }} />
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              {" "}
                              <b>{i18next.t("Kilometer_driven")}</b>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                              {" "}
                              {tourData?.kilometerTotal?.toFixed(1)} km
                            </Col>
                          </Row>
                          <hr style={{ margin: "revert" }} />
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              {" "}
                              <b>{i18next.t("Minutes_taken")}</b>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                              {" "}
                              {tourData?.minutesTotal} (h:m)
                            </Col>
                          </Row>
                          <hr style={{ margin: "revert" }} />
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              {" "}
                              <b>{i18next.t("Average_speed")}</b>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                              {" "}
                              {tourData?.speedAvg?.toFixed(1)} km/h
                            </Col>
                          </Row>
                          <hr style={{ margin: "revert" }} />
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              {" "}
                              <b>{i18next.t("Max_speed")}</b>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                              {" "}
                              {tourData?.speedMax?.toFixed(1)} km/h
                            </Col>
                          </Row>
                          <hr style={{ margin: "revert" }} />
                          <Row>
                            <Col style={{ textAlign: "left" }}>
                              {" "}
                              <b>{i18next.t("minutes_in_breaks")}</b>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                              {" "}
                              {tourData?.minutesBreaks} (h:m)
                            </Col>
                          </Row>
                        </Container>
                      </>
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AnalyticMap;
