import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Security from "../pages/settingUser/Security";
import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";
import ClientInfo from "../pages/settingUser/ClientInfo";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from "react-router-dom";
import i18next from "i18next";


function SideBarSetting({ children }) {

    const { collapseSidebar } = useProSidebar();
    const [selectedComponent, setSelectedComponent] = useState(children[0]?.component || null);
    const windowSize = useWindowSize();
    //eslint-disable-next-line
    const [isMobile, setIsMobile] = useState(windowSize[0] < 992 ? true : false);
    const navigate = useNavigate();



    /**
     * 
     */
    useEffect(() => {
        isMobile && collapseSidebar()
        //eslint-disable-next-line
    }, [isMobile]);

    /**
     * 
     * @param {*} component 
     */
    const handleMenuItemClick = (component) => {
        //collapseSidebar(); // Collapse sidebar after clicking a menu item
        setSelectedComponent(component);
    };

    const BackToMap = () => {
        navigate('/devices', { replace: true });
    }

    /**
     * 
     * @returns 
     */
    const renderSelectedComponent = () => {
        switch (selectedComponent) {
            case 'ClientInfo':
                return <ClientInfo />;
            case 'Security':
                return <Security />;
            default:
                return null;
        }
    };

    return (
        <>
            <div id="app" className="mainWidth" style={({ height: "100vh", display: "flex", position: "fixed" })}>

                <Sidebar style={{ height: "100vh" }} >
                    <Menu>
                        <MenuItem
                            icon={<MenuOutlinedIcon />}
                            onClick={() => { collapseSidebar() }}
                            style={{ textAlign: "center" }}
                        >
                            {" "}
                        </MenuItem>

                        {children.map((child, index) => (
                            <MenuItem key={index} icon={child.icon} onClick={() => handleMenuItemClick(child.component)}>
                                {child.name}
                            </MenuItem>
                        ))}
                        <div style={{ flex: "1", height: "57vh" }} /> {/* This empty div will push the "Map" MenuItem to the bottom */}

                        <MenuItem
                            icon={<ArrowCircleLeftOutlinedIcon />}
                            onClick={() => { BackToMap() }}
                        >
                            {i18next.t('Map')}
                        </MenuItem>
                    </Menu>
                </Sidebar>
                <main style={{ width: "-webkit-fill-available", overflow: "auto" }}>
                    {renderSelectedComponent()}
                </main>
            </div>

        </>
    );
}

export default SideBarSetting;