/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
// add this before each Route => https://vcm.okit.de

/**
 * This component represents the the URLs api to connect with WebMapAPi.
 *
 * @author hatem sfar
 *
 */
const apiList = {
  /*******************  CLIENT API  *******************/
  ClientServiceProvider: `/api/client/byServiceProvider`,
  ClientUser: `/api/client/byClientPrefix`,
  ClientUpdate: `/api/client/update`,
  /********************  DEVICE API  *********************/
  DeviceListClient: `/api/device/list`,
  DeviceDetail: `/api/device/detail/`,
  DeviceAlert: `/api/alert/detail/`,
  DeviceUnique: `/api/device/uniquedevices`,
  DeviceUpdate: `/api/device/update`,
  DeviceSendSMSTest: `/api/device/testMobileNumber`,
  DeviceSendMailTest: `/api/device/testSMSMail`,
  CheckHealthServer: `/api/device/health`,
  /*********************  CAS API  **********************/
  CasClient: `/api/auth/cas/`,
  StatusServer: `/status`,
  CasByPass: `/api/auth/cas/casbypass`,
  /***************  MEASUREMENT API  *******************/
  MeasurementTires: `/api/measurement/measurement/`,
  MeasurementNameSpaces: `/api/measurement/measurement/namepsaces/`,
  LastMeasurement: `/api/measurement/measurements/`,
  SetMeasurement: `/api/measurement/measurement/set/`,
  IntervalMeasurement: `/api/measurement/measurements/intervall/`,
  HistoryMeasurement: `/api/measurement/measurements/history/`,
  MeasurementRange: `/api/measurement/range`,
  CheckTokenMeasurement: `/api/measurement/checkToken`,
  /***************  NODE_DEVICE API  ******************/
  NodeDeviceList: `/api/nodedevice/list/`,
  /********************  NODE API  *********************/
  NodeList: `/api/node/list`,
  /****************  COORDINATE API  ******************/
  CoordinateList: `/api/coordinate/list`,
  CoordinateDevice: `/api/coordinate/coordinatedevice`,
  CoordinateIntervalList: `/api/coordinate/list/interval`,
  CoordinateIntervalListPagination: `/api/coordinate/list/pagination`,
  CoordinateTourData: `/api/coordinate/tourdata`,
  /*******************  SESSION API  *******************/
  UpdateSession: `/api/session/updatesession/`,
  SessionClientPrefix: `/api/session/session`,
  CheckConnx: `/api/checkconx`,
  Sms: `/api/sendsms`,
};
export default apiList;
