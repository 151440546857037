/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import axios from "axios";
import apiList from "../utils/apiClient.js";

/**
 * This component implements the api's call
 * 
 * @author hatem sfar
 *
 */
const getCoordinateslistApi = async (devicesList) => {
    const { data } = await axios.get(apiList.CoordinateList, {
        params: { devices: devicesList }
    });
    return data;
}

const getCoordinatesIntervallApi = async (device, isoDateStart, isoDateEnd, token, pageNumber) => {
    try {
        const { data } = await axios.get(apiList.CoordinateIntervalList, {
            params: { device, dateStart: isoDateStart, dateEnd: isoDateEnd, pageNumber },
            cancelToken: token, // Pass the cancel token
        });
        const isFirstPage = pageNumber === 1;
        const isLastPage = !data.result.nextPage;
        return {
            isFirstPage,
            isLastPage,
            data
        };
    } catch (error) {
        throw error;
    }
};


const getCoordinatesIntervallPaginationApi = async (device, isoDateStart, isoDateEnd, pageNumber, lat1, lng1, lat2, lng2) => {
    const { data } = await axios.get(apiList.CoordinateIntervalListPagination, {
        params: { device: device, dateStart: isoDateStart, dateEnd: isoDateEnd, pageNumber: pageNumber, lat1: lat1, lng1: lng1, lat2: lat2, lng2: lng2 },
    });
    return data;
}

const getLastCoordinateDeviceApi = async (device) => {
    const { data } = await axios.get(apiList.CoordinateDevice, {
        params: { device: device }
    });
    return data;
}

const getTourDataApi = async (device, isoDateStart, isoDateEnd) => {
    const { data } = await axios.get(apiList.CoordinateTourData, {
        params: { device: device, dateStart: isoDateStart, dateEnd: isoDateEnd },
    });
    return data;
}

export {
    getCoordinatesIntervallApi,
    getCoordinateslistApi,
    getCoordinatesIntervallPaginationApi,
    getLastCoordinateDeviceApi,
    getTourDataApi
}
