import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  DropDownButton: {
    //margin: "50px 50px",
    //fontSize: "1.125rem",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "2px solid #9494df",
    //borderRadius: "10px",
    backgroundColor: "rgb(140, 140, 240)",
    cursor: "pointer",
    padding: "0px 20px",

  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    //marginRight: "20px",
    //marginLeft: 0,
    width: "100%",
    border: "1px solid grey"
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  searchBarContainer: {
    maxWidth: "max-content",
    display: "flex",
    // justifyContent: "space-evenly",
    cursor: "default",
    "&.MuiListItem-button": {
      "&:hover": {
        backgroundColor: "white"
      }
    }
  },
  menuDivider: {
    margin: "0 20px"
  },
  dashboardSelectMenu: {
    marginTop: "0px"

  },
  externalLinkIcon: {
    borderLeft: "1px solid var(--color-gray-eighty-five)",
    padding: "10px 0px 10px 10px",
    color: "var(--color-primary)",
    cursor: "pointer"
  },
  checkedItem: {
    color: "indigo"
  },
}));

export default useStyles;
