/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Germanyflag from "../../assets/images/germanyflag.svg";
import Ukflag from "../../assets/images/ukflag.svg";

/**
 * This component represents the DropDown
 * list to choose the language for the vcm app
 * 
 * @author hatem sfar
 *
 */


const LangDropDown = () => {

  const [open, setOpen] = useState(false); // State to manage dropdown open/close
  const [lang, setLang] = useState(localStorage.getItem("lang") || "de"); // State to manage selected language

  const handleChange = (event) => {
    const newLang = event.target.value;
    localStorage.setItem("lang", newLang);
    setLang(newLang);
    setOpen(false); // Close the dropdown
    window.location.reload();
  };

  const handleSelect = () => {
    setOpen(!open);
  };


  return (
    <Select
      open={open}
      name={"selectLong"}
      onClick={handleSelect}
      onChange={handleChange}
      labelId="simple-select"
      id="demo-simple-select"
      className="select_cp"
      disableUnderline
      value={lang}
      style={{
        borderRadius: "5px",
        paddingLeft: "10px"

      }}
      MenuProps={{
        PaperProps: {
          style: {
            marginTop: "40px",
            marginLeft: "-10px"
          },
        },

      }}

    >
      <MenuItem value="de">
        <img
          className="w-6 h-5"
          aria-hidden="true"
          src={Germanyflag}
          alt="de"
          width="23px"
        />
      </MenuItem>
      <MenuItem value="en">
        <img
          className="w-6 h-5"
          aria-hidden="true"
          src={Ukflag}
          alt="en"
          width="23px"
        />
      </MenuItem>
    </Select>
  );
};

export default LangDropDown;