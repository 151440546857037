
import React from "react"


export function LoadingAnalytic({ pos }) {
    return (
        <>
            <div style={{ zIndex: 999, position: pos }} className="loadingAnalytic">
                <div className="loaderAnalytic"></div>
            </div>
        </>
    )
}