
import iconPinGood from "../assets/images/pin-greenpin.svg";
import iconPinWarning from "../assets/images/pin-orangepin.svg";
import iconPinError from "../assets/images/pin-redpin.svg"
import iconPniNoAxle from "../assets/images/pin-bluepin.svg"
import iconPinSwitchOn from "../assets/images/pin-switch-on.svg"
import iconPinSwitchOff from "../assets/images/pin-switch-off.svg"
import icePin from "../assets/images/ice.svg";
import snowPin from "../assets/images/snow.svg";
import water_1Pin from "../assets/images/water_1.svg";
import water_2Pin from "../assets/images/water_2.svg";
import water_3Pin from "../assets/images/water_3.svg";
import FrictionCoefficientPin from "../assets/images/FrictionCoefficient.svg";
import defaultPin from "../assets/images/default.svg";
import dryIcon from "../assets/images/dryIcon.svg";





import L from "leaflet"

const iconPinGood_cs = new L.Icon({
    iconSize: [50, 50],
    iconUrl: iconPinGood,
    iconAnchor: [25, 25],
});

const iconPinWarning_cs = new L.Icon({
    iconSize: [50, 50],
    iconUrl: iconPinWarning,
    iconAnchor: [25, 25],

});

const iconPinError_cs = new L.Icon({
    iconSize: [50, 50],
    iconUrl: iconPinError,
    iconAnchor: [25, 25],
});

const iconPniNoAxle_cs = new L.Icon({
    iconSize: [50, 50],
    iconUrl: iconPniNoAxle,
    iconAnchor: [25, 25],
});
const iconPinSwitchOn_cs = new L.Icon({
    iconSize: [50, 50],
    iconUrl: iconPinSwitchOn,
    iconAnchor: [10, 10],

});
const iconPinSwitchOff_cs = new L.Icon({
    iconSize: [50, 50],
    iconUrl: iconPinSwitchOff,
    iconAnchor: [10, 10],

});




export {
    iconPinGood,
    iconPinWarning,
    iconPinError,
    iconPniNoAxle,
    iconPinSwitchOn,
    iconPinSwitchOff,
    icePin,
    snowPin,
    water_1Pin,
    water_2Pin,
    water_3Pin,
    defaultPin,
    FrictionCoefficientPin,
    dryIcon,
    iconPinGood_cs,
    iconPinWarning_cs,
    iconPinError_cs,
    iconPniNoAxle_cs,
    iconPinSwitchOn_cs,
    iconPinSwitchOff_cs
}