/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich gesch├╝tzt.
 */
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SideBarSetting from '../../utils/SideBarSetting';
import { ProSidebarProvider } from 'react-pro-sidebar';
import "../../assets/css/setting.css";
import { clientPrefixUserApi } from '../../hooks/clientPrefixApi';


/**
 * This component is for the user to update his personal informations
 *  
 * @author hatem sfar
 *
 */
const Setting = () => {

    const [client, setClient] = useState([])


    useEffect(() => {
        getClient()
        //eslint-disable-next-line
    }, []);

    const getClient = () => {
        try {
            clientPrefixUserApi()
                .then((data) => {
                    setClient(data);
                })
        } catch (err) {
            if (err.response && err.response.status === 504) {
                console.error("Gateway Time-out: The server took too long to respond.");
            }
            console.log(err);
        }
    };

    
    const children = [
        { name: i18next.t("ClientInfo"), icon: <PersonOutlineOutlinedIcon />, component: "ClientInfo" },
        { name: i18next.t("Security"), icon: <LockOutlinedIcon />, component: "Security" },
    ];

    return (
        <>
            <ProSidebarProvider>
                <SideBarSetting children={children} data={client} />
            </ProSidebarProvider>
        </>
    );
};
export default Setting;
