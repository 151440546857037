/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React from 'react';
import crosshair from "../../assets/images/crosshair.svg";
import { useMap } from 'react-leaflet';
import { latLngBounds } from 'leaflet';

/**
 * This component represents reset button to resset the screen zoom
 * 
 * @author hatem sfar
 *
 */
const ResetButton = ({ coordinate, setSelectedDevice, setShowSpeedometer }) => {

    const map = useMap();
    let markerBounds = latLngBounds([]);
    const onClick = () => {
        //markerBounds.extend([position[0], position[1]])
        setSelectedDevice(null)
        setShowSpeedometer(false)
        if (coordinate?.length && coordinate?.length > 0) {
            coordinate.forEach(marker => {
                markerBounds.extend([marker.latitude, marker.longitude])
            })
            // Adjust the zoom-out factor here (e.g., 0.5 for half the zoom level)
            const zoomOutFactor = 0.5;
            map.fitBounds(markerBounds.pad(zoomOutFactor));
        }
    };

    return (
        <>
            <div className='reset_pos_btn'>
                <img
                    src={crosshair}
                    onClick={onClick}
                    height="20"
                    className="d-inline-block"
                    alt="crosshair"
                />

            </div>
        </>
    );
}
export default ResetButton;