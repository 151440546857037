/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import useCas from "../auth/useCas";

/**
 * This component represents the the LogOut form vcm app
 * 
 * @author hatem sfar
 *
 */
function Logout(props) {
    const cas = useCas();
    return cas.casLogout();
}

export default Logout;