import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TimeOutToast from "../../utils/TimeOutToast";
import { Input } from "antd";
import {
  getMeasurementByNameSpaceApi,
  setMeasurementByNameSpaceApi,
} from "../../hooks/measurementApi";
import QRCode from "qrcode.react";
import useWindowSize from "../../utils/useWindowSize";
import { toast } from "react-toastify";
import { sendSMSTestDeviceApi } from "../../hooks/deviceApi";

export default function DriverApp({ device }) {
  const [pin, setPin] = useState();
  const [token, setToken] = useState();
  const [qrText, setQrText] = useState("");
  const windowSize = useWindowSize();
  const [driverNumber, setDriverNumber] = useState("");

  useEffect(() => {
    getMeasurement();
    // eslint-disable-next-line
  }, [device]);

  /**
   * to handle the input in the field
   * @param {*} e
   */
  const handleInputChangeDriverNumber = (e) => {
    const newValue = e.target.value;
    if (driverNumber !== newValue) {
      setDriverNumber(newValue);
    }
  };

  /**
   *
   */
  const updateMeasurement = async () => {
    const validTags =
      driverNumber?.startsWith("0049") || driverNumber?.startsWith("+49");

    // If there are any invalid tags, show the toast error
    if (!validTags) {
      toast.warning(i18next.t("wrongnumberformat"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
      setDriverNumber(null);
    } else {
      const namespace = "de.okit.automotive.device.DriverMobileNumber";
      try {
        setMeasurementByNameSpaceApi(device.id, driverNumber, namespace).then(
          () => {
            getMeasurement();
          }
        );
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
        console.log(err);
      }
    }
  };
  /**
   *
   */
  const getMeasurement = async () => {
    const position =
      "de.okit.automotive.device.PIN" +
      "," +
      "de.okit.automotive.device.TOKEN" +
      "," +
      "de.okit.automotive.device.DriverMobileNumber";

    getMeasurementByNameSpaceApi(device.id, position)
      .then((response) => {
        response &&
          response?.forEach((info) => {
            if (info && info.namespace.endsWith("PIN")) {
              if (info.measurements && info.measurements.length > 0) {
                const pinMeasurement = info.measurements[0];
                const pinValue = pinMeasurement.content;
                const measurementDate = new Date(
                  pinMeasurement.measurementdate
                );

                const twentyFourHoursAgo = new Date();
                twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

                if (measurementDate < twentyFourHoursAgo) {
                  // Measurement is older than 24 hours, set PIN to null
                  setPin(null);
                } else {
                  // Measurement is recent, set the PIN value
                  setPin(pinValue);
                  const qrCodeText = generateQRCode(pinValue);
                  setQrText(qrCodeText);
                }
              }
            }
            if (info && info.namespace.endsWith("TOKEN")) {
              if (info.measurements && info.measurements.length > 0) {
                setToken(info?.measurements[0]?.content);
              }
            }
            if (info && info.namespace.endsWith("DriverMobileNumber")) {
              if (info.measurements && info.measurements.length > 0) {
                setDriverNumber(info?.measurements[0]?.content);
              }
            }
          });
      })
      .catch((error) => {
        if (error.response && error.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
      });
  };

  /**
   *
   */
  const generateNewPin = () => {
    const pin = Math.floor(100000 + Math.random() * 900000);
    const namespace = "de.okit.automotive.device.PIN";
    try {
      setMeasurementByNameSpaceApi(device.id, pin, namespace).then(() => {
        getMeasurement();
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
      console.log(err);
    }
  };

  /**
   *
   */
  const generateNewToken = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 32; // Adjust the length of the token as needed
    let token = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters[randomIndex];
    }
    //console.log("New Token:", token);
    const namespace = "de.okit.automotive.device.TOKEN";
    try {
      setMeasurementByNameSpaceApi(device.id, token, namespace).then(() => {
        getMeasurement();
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
      console.log(err);
    }
  };

  /**
   *
   */
  const generateQRCode = (pinValue) => {
    //const appLink = 'https://play.google.com/store/apps/details?id=com.activision.callofduty.warzone&hl=en&gl=US'; // Replace this with your actual app link
    const qrText = `deviceID=${device.id}&PIN=${
      pinValue != null ? pinValue : pin
    }`;
    return qrText;
  };
  /**
   *
   */
  const handleGenerateQRCode = () => {
    if (!pin) {
      generateNewPin();
    }

    const qrCodeText = generateQRCode();
    setQrText(qrCodeText);
  };

  /**
   *
   */
  const handleRevokeAllDevices = () => {
    if (pin) {
      generateNewPin();
    }
    generateNewToken();
  };

  const sendTestSMS = () => {
    const validTags =
      driverNumber?.startsWith("0049") || driverNumber?.startsWith("+49");
    if (!validTags) {
      toast.error(i18next.t("msgnotsendsms"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      const messageSMS = i18next.t("smstestmsg");
      const tag = `Deviceid: ${device.id}`;
      sendSMSTestDeviceApi(driverNumber, messageSMS, tag)
        .then((response) => {
          if (response?.statusCode !== 400) {
            toast.info(i18next.t("msgsendsms"), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              theme: "colored",
              style: {
                top: "50px",
              },
            });
          } else {
            toast.error(i18next.t("msgnotsendsms"), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              theme: "colored",
              style: {
                top: "50px",
              },
            });
          }
        })
        .catch((error) => {
          console.error("error ", error);
        });
    }
  };

  return (
    <Container
      fluid
      style={{ width: windowSize[0] > 991 ? "50%" : "100%", float: "left" }}
    >
      <h3
        style={{ marginLeft: "15px", marginTop: "10px" }}
        className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
      >
        {i18next.t("DriverApp")}{" "}
      </h3>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("DriverNumber")}</font>
        </Col>
        <Col>
          <Input
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
            onChange={handleInputChangeDriverNumber}
            onBlur={updateMeasurement}
            type="text"
            value={driverNumber}
          />
          <Button
            onClick={sendTestSMS}
            style={{
              backgroundColor: "#8c8cf0",
              color: "white",
              border: "1px solid #0d6efd",
              //height: "10px",
              width: "160px",
              fontSize: "13px",
              marginTop: "5px",
            }}
            className="mb-4"
            size="large"
          >
            {i18next.t("Send_Test_SMS")}
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("DeviceID")}</font>
        </Col>
        <Col>
          <Input
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
            type="text"
            value={device.id}
            disabled={true}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("pin")}</font>
        </Col>
        <Col>
          <Input
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
            type="text"
            value={pin}
            disabled={true}
          />{" "}
          {/*
                    <Link >
                        <font size="3" onClick={generateNewPin}>{i18next.t('newPin')}</font>
                    </Link>
                */}
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("token")}</font>
        </Col>
        <Col>
          <Input
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
            type="text"
            value={token}
            disabled={true}
          />{" "}
          {/*
                    <Link >
                        <font size="3" onClick={generateNewToken}>{i18next.t('newToken')}</font>
                    </Link>
                    */}
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <Button
            onClick={handleGenerateQRCode}
            style={{ backgroundColor: "#8c8cf0" }}
          >
            {i18next.t("invitaion")}
          </Button>
          <br />
          <br />
          <Button
            onClick={handleRevokeAllDevices}
            style={{ backgroundColor: "#8c8cf0" }}
          >
            {i18next.t("revokealldevices")}
          </Button>
        </Col>
        <Col>{qrText && pin && <QRCode value={qrText} />}</Col>
      </Row>
    </Container>
  );
}
