/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import axios from "axios";
import apiList from "../utils/apiClient.js";

/**
 * This component implements the api's call
 * 
 * @author hatem sfar
 *
 */
const getSessionClientPrefixApi = async () => {
    const { data } = await axios.get(apiList.SessionClientPrefix);
    return data;
}

export {
    getSessionClientPrefixApi
}
