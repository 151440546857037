/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  ScaleControl,
  TileLayer,
  ZoomControl,
  useMap,
  useMapEvents
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../assets/css/map.css";
import ReloadButton from "./ReloadDataButton";
import ResetButton from "./ResetPosButton";
import AnalyticMap from "../analyticMap/AnalyticMap";
import { useNavigate } from "react-router-dom";
import SideBar from "../sideBarDevices/SideBar";
import L, { latLngBounds } from "leaflet";
import {
  iconPinGood,
  iconPinWarning,
  iconPinError,
  iconPniNoAxle,
} from "../../utils/iconPin";
import "../../assets/css/loading.css";
import { Loading } from "../../utils/loading";
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker";
import MarketClusterGroup from "react-leaflet-cluster";
import { v4 as uuidv4 } from "uuid";
import { getDeviceApi } from "../../hooks/deviceApi";
import { getCoordinateslistApi } from "../../hooks/coordinatesApi";
import { getSessionClientPrefixApi } from "../../hooks/sessionClientPrefixApi";
import { ChangeDataWS } from "../../utils/changeDataWS";
import useWindowSize from "../../utils/useWindowSize";
import RadarMap from "../radarMap/RadarMap";
import TimeOutToast from "../../utils/TimeOutToast";
import SpeedometerView from "./Speedometer";
import { getMeasurementByNameSpaceApi } from "../../hooks/measurementApi";
import TracePolylines from "../analyticMap/TracePolylines";
import BreaksPolylines from "../analyticMap/BreaksPolylines";
import TopSpeedsPolylines from "../analyticMap/TopSpeedsPolylines";
import i18next from "i18next";

/**
 * This component implements the map
 *
 * @author hatem sfar
 *
 */
export default function Map() {
  const mapRef = useRef(null);
  const defaultPosition = [51.420717, 7.3015];
  //eslint-disable-next-line
  const [position, setPosition] = useState(defaultPosition);
  const markerRefs = useRef({});
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [coordinates, setCoordinates] = useState([]);
  const [oldCoordinates, setOldCoordinates] = useState([]);
  const [analytic, setAnalytic] = useState(
    localStorage.getItem("sidebar") || false
  );
  const [hoveredCoordinate, setHoveredCoordinate] = useState(null);
  const [deviceCoordinate, setDeviceCoordinate] = useState([]);
  const navigate = useNavigate();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const windowSize = useWindowSize();
  const [zoomLevelStorage, setZoomLevelStorage] = useState(
    localStorage.getItem("zoomLevel") || null
  );
  const [positionStorage, setPositionStorage] = useState(
    localStorage.getItem("position") || null
  );
  const [showSpeedometer, setShowSpeedometer] = useState(false);
  //eslint-disable-next-line
  const [speeds, setSpeeds] = useState({});
  const [maxSpeedDevice, setMaxSpeedDevice] = useState(0);
  const cacheIsDevicesHidden = localStorage.getItem("hiddenDevices");
  const [isRadarActive, setIsRadarActive] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  let shadowMarkerRefs = useRef({});
  const [isTraceActive, setIsTraceActive] = useState(true);
  const [isBreakActive, setIsBreakActive] = useState(false);
  const [isTopSpeedActive, setIsTopSpeedActive] = useState(false);

  /**
   * to get the devices and coordinates at first loading
   */
  useEffect(() => {
    getSessionClientPrefix();
    getDeviceList();
    //eslint-disable-next-line
  }, []);

  /**
   * get the device list from DB
   */
  const getDeviceList = async () => {
    try {
      getDeviceApi()
        .then(async (response) => {
          const deviceList = response ? response : [];
          setDevices(deviceList);

          if (deviceList.length > 0) {
            // console.log('goes in ... 1')
            await getCoordinateList(deviceList);
            //eslint-disable-next-line
            deviceList.map((e) => {
              localStorage.setItem(`alert-${e.id}`, e.alert);
            });
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 504) {
            console.error(
              "Gateway Time-out: The server took too long to respond."
            );
            TimeOutToast();
            setIsLoading(false);
          }
          setIsLoading(false);
          console.log("error: ", err.response.status);
        });
    } catch (error) {
      if (error.response && error.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };
  /**
   * get the coordinate list
   * @param {*} res
   */
  const getCoordinateList = async (devices) => {
    try {
      const devicesList = devices.map((device) => ({
        id: device.id,
        name: device.name,
        alert: device.alert,
      }));

      getCoordinateslistApi(devicesList).then((response) => {
        const coordinateList = response ? response : [];
        const updatedDeviceCoordinate = devices.map((device) => {
          const correspondingCoordinate = coordinateList.find(
            (coordinate) => coordinate.device === device.id
          );
          return {
            ...device,
            coordinate: correspondingCoordinate || null,
          };
        });

        if (cacheIsDevicesHidden === "true") {
          const filtered = devices.filter((card) => card.active);
          const filteredDeviceIds = filtered.map((card) => card.id);
          const filteredCoordinates = response.filter((coord) =>
            filteredDeviceIds.includes(coord.device)
          );
          setCoordinates(filteredCoordinates);
        } else {
          setCoordinates(coordinateList);
        }
        setOldCoordinates(coordinateList);
        setDeviceCoordinate(updatedDeviceCoordinate);
        setIsDataLoaded(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
        setIsLoading(false);
        setIsDataLoaded(true);
      }
      console.log(err);
      setIsLoading(false);
      setIsDataLoaded(true);
    }
  };

  /**
   *
   * @returns to CheckPosition for all the coordinates
   */
  function CheckPosition() {
    const map = useMap();
    const markerBounds = latLngBounds([]);
    let position = [];

    // save the new coordinate with zoomLevel into Storage
    useMapEvents({
      zoomend: () => {
        const zoomLevel = map.getZoom();
        localStorage.setItem("zoomLevel", zoomLevel);
        setZoomLevelStorage(zoomLevel);
      },
      moveend: () => {
        const newPosition = map.getCenter();
        localStorage.setItem("position", JSON.stringify(newPosition));
        setPositionStorage(JSON.stringify(newPosition));
        setPosition(newPosition);
      },
    });

    // useEffect to set the map view based on stored values
    useEffect(() => {
      if (isDataLoaded) {
        setIsDataLoaded(false);
        if (zoomLevelStorage && positionStorage) {
          const storedPosition = JSON.parse(positionStorage);
          const storedZoomLevel = parseInt(zoomLevelStorage, 10);

          // Check if the stored position is different from the current position
          if (
            storedPosition[0] !== position?.lat ||
            storedPosition[1] !== position?.lng ||
            storedZoomLevel !== map.getZoom()
          ) {
            map.setView(storedPosition, storedZoomLevel);
          }
        } else {
          // setBounds for all coordinates to be in the same view area
          if (oldCoordinates && oldCoordinates?.length > 0) {
            oldCoordinates.forEach((marker) => {
              markerBounds.extend([marker.latitude, marker.longitude]);
            });
            // Adjust the zoom-out factor here (e.g., 0.5 for half the zoom level)
            const zoomOutFactor = 0.5;
            map.fitBounds(markerBounds.pad(zoomOutFactor));
          }
        }
      }
      //eslint-disable-next-line
    }, [map, isDataLoaded]);

    return null;
  }

  /**
   *
   * @param {*} data
   */
  const handleDataFromChild = async (data) => {
    const newData = coordinates.find((device) => device.device === data.id);
    if (newData && newData.latitude && newData.longitude) {
      const newPosition = [newData.latitude, newData.longitude];
      //console.log("newPosition: ", newPosition)
      mapRef.current.setView(newPosition, 20); // Zoom level
      localStorage.setItem("zoomLevelLat", newData.latitude);
      localStorage.setItem("zoomLevelLong", newData.longitude);
    }
    setSelectedDevice(data.id);

    try {
      await getMeasurementByNameSpaceApi(
        data.id,
        "de.okit.automotive.device.MaxSpeed"
      ).then((response) => {
        //console.log("response: ", response[0].measurements[0])
        setMaxSpeedDevice(
          response[0]?.measurements
            ? response[0]?.measurements[0]?.content
            : 200
        );
      });
    } catch (err) {
      setMaxSpeedDevice(0);
      console.log("err in maxSpeed from speedometer");
    }
    // Set the state to true to display the Speedometer
    setShowSpeedometer(true);
  };

  /**
   * keep tracking the device by updating the view when the device is on focus
   * @param {*} data
   */
  useEffect(() => {
    if (selectedDevice && !analytic) {
      const newData = coordinates.find(
        (device) => device.device === selectedDevice
      );
      if (newData && newData.latitude && newData.longitude) {
        const newPosition = [newData.latitude, newData.longitude];
        mapRef.current.setView(newPosition, 20);

        // Update the specific marker position if it's not in the current view
        const selectedMarker = markerRefs.current[selectedDevice];
        if (
          selectedMarker &&
          !mapRef.current.getBounds().contains(selectedMarker.getLatLng())
        ) {
          selectedMarker.setLatLng(newPosition);
        }
        localStorage.setItem("zoomLevelLat", newData.latitude);
        localStorage.setItem("zoomLevelLong", newData.longitude);

        const measurementDate = new Date(newData.measurementdate);
        // Get the current time
        const currentTime = new Date();
        // Calculate the difference in milliseconds
        const timeDifferenceMs = currentTime - measurementDate;

        // Check if the difference is greater than 1 minute (60 seconds)
        if (timeDifferenceMs > 60 * 1000) {
          // If the data is older than 1 minute, set the speed to 0
          setSpeeds({ [selectedDevice]: 0 });
        } else {
          // If the data is within the last 1 minute, set the speed to newData.speed
          setSpeeds({ [selectedDevice]: newData.speed || 0 });
        }
      }
    }
    //eslint-disable-next-line
  }, [coordinates, analytic]);

  /**
   * to get the session of the clientPrefix
   */
  const getSessionClientPrefix = async () => {
    try {
      await getSessionClientPrefixApi().then((response) => {
        localStorage.setItem("clientPrefix", response ? response : null);
      });
    } catch (error) {
      if (error.response && error.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
        setIsLoading(false);
      }
    }
  };

  /**
   * @param {*} coordinate
   * @returns {L.Icon|null} Returns an icon object or null if there is an issue.
   */
  function createIcon(coordinate) {
    // console.log("coordinate: ", coordinate)

    // Check if devices is not available or coordinate is not provided
    if (!coordinate) {
      //console.log("Coordinate is not provided.");
    }
    if (!devices || devices.length === 0) {
      // Handle the case where devices is not available
      //console.log("Devices is not provided.")
    }

    // Find the device based on the coordinate's device ID
    const device = devices?.find((device) => device.id === coordinate.device);

    // Check if a matching device is found
    if (!device) {
      //console.log("Device not found")
      //return null;
    }

    //console.log("coordinate: ", coordinate)
    coordinate.alert = device ? device.alert : "noaxle";

    const iconSize = [50, 50];

    if (cacheIsDevicesHidden === "true" && !device?.active) {
      return L.divIcon({
        className: "invisible-icon",
        iconSize: iconSize,
        iconAnchor: [25, 25],
        // Handle the rotation animation
        iconAngle: coordinate.azimuth,
      });
    }

    return L.icon({
      iconUrl:
        coordinate.alert === "error"
          ? iconPinError
          : coordinate.alert === "warning"
          ? iconPinWarning
          : coordinate.alert === "good"
          ? iconPinGood
          : iconPniNoAxle,
      iconSize: iconSize,
      iconAnchor: [25, 25],
      // Handle the rotation animation
      iconAngle: coordinate.azimuth,
    });
  }

  /**
   *
   * @param {*} cluster
   * @returns
   */
  const createClusterCustomIcon = function (cluster) {
    const childMarkers = cluster.getAllChildMarkers();
    const clustererror = childMarkers.filter(
      (a) => a.options.options.coordinate.alert === "error"
    );
    const clusterwarning = childMarkers.filter(
      (a) => a.options.options.coordinate.alert === "warning"
    );
    const clustergood = childMarkers.filter(
      (a) => a.options.options.coordinate.alert === "good"
    );
    const clusternoaxle = childMarkers.filter(
      (a) => a.options.options.coordinate.alert === "noaxle"
    );

    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: clustererror[0]?.options?.options?.coordinate
        ? `cluster-icon-${clustererror[0]?.options?.options?.coordinate?.alert}`
        : clusterwarning[0]?.options?.options?.coordinate
        ? `cluster-icon-${clusterwarning[0]?.options?.options?.coordinate?.alert}`
        : clustergood[0]?.options?.options?.coordinate
        ? `cluster-icon-${clustergood[0]?.options?.options?.coordinate?.alert}`
        : clusternoaxle[0]?.options?.options?.coordinate
        ? `cluster-icon-${clusternoaxle[0]?.options?.options?.coordinate?.alert}`
        : `cluster-icon-${clusternoaxle[0]?.options?.options?.coordinate?.alert}`,

      iconSize: L.point(33, 33, true),
      iconAnchor: [25, 25],
    });
  };

  /**
   * Handle cluster click event
   * @param {Object} cluster - The clicked cluster
   */
  const handleClusterClick = (cluster) => {
    if (cluster && cluster.getAllChildMarkers) {
      //eslint-disable-next-line
      const childMarkers = cluster.getAllChildMarkers();
      // Handle your logic here for cluster click
      // For example, you can zoom to the bounds of childMarkers or perform other actions.
    }
  };

  /**
   * keep tracking the mouse movement for hover the device
   */
  useEffect(() => {
    const handleMouseMoveMap = () => {
      if (hoveredCoordinate) {
        setHoveredCoordinate(null);
      }
    };

    const mapContainer = document.querySelector(".leaflet-container");

    if (mapContainer) {
      mapContainer.addEventListener("mousemove", handleMouseMoveMap);
    }

    return () => {
      if (mapContainer) {
        mapContainer.removeEventListener("mousemove", handleMouseMoveMap);
      }
    };
  }, [hoveredCoordinate]);

  /**
   *
   * @param {*} data
   */
  const handleMouseHover = (data) => {
    const measurementdate = data?.measurementdate;
    const msg = i18next.t("popupLabelMarker");
    L.popup()
      .setLatLng([data?.latitude, data?.longitude])
      .setContent(
        `${msg} 
        ${formatTimestamp(measurementdate ? measurementdate : null)}`
      )
      .openOn(mapRef.current);

    setHoveredCoordinate(data ? data : null);
  };

  // Create a method to format the timestamp
  const formatTimestamp = (timestamp) => {
    if (timestamp === null) {
      return null;
    }
    return timestamp
      ? `
      ${String(new Date(timestamp).getDate()).padStart(2, "0")}.${String(
          new Date(timestamp).getMonth() + 1
        ).padStart(2, "0")}.${new Date(timestamp).getFullYear()}

      ${i18next.t("popupMarkerumAt")}
      

      ${String(new Date(timestamp).getHours()).padStart(2, "0")}:${String(
          new Date(timestamp).getMinutes()
        ).padStart(2, "0")} 
        
      ${i18next.t("popupMarkerUhr")}
      .

      `
      : "-";
  };

  return (
    <>
      <ChangeDataWS
        setDevices={setDevices}
        setCoordinates={setCoordinates}
        devices={devices}
        setDeviceCoordinate={setDeviceCoordinate}
      />
      {isLoading && <Loading />}
      <div className="wrapper">
        <SideBar
          device={devices}
          isLoading={isLoading}
          onData={handleDataFromChild}
          hoveredCoordinate={hoveredCoordinate}
          deviceCoordinate={deviceCoordinate}
          isAnalytic={analytic}
          coordinate={coordinates}
          setCoordinates={setCoordinates}
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
          setShowSpeedometer={setShowSpeedometer}
        />

        <AnalyticMap
          devices={devices}
          mapRef={mapRef}
          setAnalytic={setAnalytic}
          isAnalytic={analytic}
          isRadarActive={isRadarActive}
          setIsRadarActive={setIsRadarActive}
          shadowMarkerRefs={shadowMarkerRefs}
          setShowSpeedometer={setShowSpeedometer}
          focusDevice={selectedDevice}
          isTraceActive={isTraceActive}
          setIsTraceActive={setIsTraceActive}
          isBreakActive={isBreakActive}
          isTopSpeedActive={isTopSpeedActive}
          setIsBreakActive={setIsBreakActive}
          setIsTopSpeedActive={setIsTopSpeedActive}
        />

        {!analytic && (
          <RadarMap
            deviceCoordinate={deviceCoordinate}
            mapRef={mapRef}
            coordinates={coordinates}
            isRadarActive={isRadarActive}
            setIsRadarActive={setIsRadarActive}
            shadowMarkerRefs={shadowMarkerRefs}
          />
        )}

        {showSpeedometer && (
          <SpeedometerView
            speed={speeds[selectedDevice] || 0}
            maxSpeedDevice={maxSpeedDevice}
          />
        )}
        <MapContainer
          ref={mapRef} // Assign the ref to the MapContainer
          center={position}
          zoom={13}
          minZoom={3}
          attributionControl={true}
          zoomControl={false}
          doubleClickZoom={false} // Disable double-click zoom
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
          }}
          style={{
            height: "100%",
          }}
        >
          <TileLayer
            url={process.env.REACT_APP_MAP_LIGHT}
            attribution={process.env.REACT_APP_ATTRIBUTION}
          />
          <ZoomControl position="topright" />
          {analytic && (
            <>
              <TracePolylines
                isTraceActive={isTraceActive}
                setIsTraceActive={setIsTraceActive}
              />
              <BreaksPolylines
                isBreakActive={isBreakActive}
                setIsBreakActive={setIsBreakActive}
              />
              <TopSpeedsPolylines
                isTopSpeedActive={isTopSpeedActive}
                setIsTopSpeedActive={setIsTopSpeedActive}
              />
            </>
          )}
          <ScaleControl imperial={false} position="bottomright" />

          {!analytic /*&& !radar*/ && (
            <>
              <CheckPosition />
              <ReloadButton getCoordinate={getDeviceList} />
              <ResetButton
                coordinate={coordinates}
                setSelectedDevice={setSelectedDevice}
                setShowSpeedometer={setShowSpeedometer}
              />
              <MarketClusterGroup
                key={uuidv4}
                iconCreateFunction={createClusterCustomIcon}
                chunkedLoading={false}
                spiderfyOnMaxZoom={true}
                showCoverageOnHover={true}
                disableClusteringAtZoom={11}
                animate={false}
                animateAddingMarkers={false}
                onMouseUp={handleClusterClick}
              >
                {coordinates?.map((coordinate) => (
                  <LeafletTrackingMarker
                    key={coordinate.device}
                    position={[coordinate.latitude, coordinate.longitude]}
                    icon={createIcon(coordinate)}
                    duration={500}
                    rotationAngle={
                      windowSize[0] < 992
                        ? coordinate.azimuth
                        : coordinate.azimuth
                    }
                    keepAtCenter={false}
                    options={{ coordinate: coordinate }}
                    eventHandlers={{
                      mouseup: () =>
                        navigate(`/devices/detail/${coordinate.device}`),
                      mouseover: () => handleMouseHover(coordinate),
                      mouseout: () => setHoveredCoordinate(null),
                    }}
                    ref={(marker) => {
                      markerRefs.current[coordinate?.device] = marker;
                    }}
                  ></LeafletTrackingMarker>
                ))}
              </MarketClusterGroup>
            </>
          )}
        </MapContainer>
      </div>
    </>
  );
}
