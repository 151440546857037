/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useState } from "react";
import { MenuItem, InputBase, Menu, Divider } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check";
import { Button } from "reactstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import i18next from 'i18next';
import auth from "../auth/auth";
import ROLES from "../utils/roles";
import useStyles from "../assets/css/clientPrefixStyles";
import { clientPrefixApi, getSessionApi, setSessionApi } from "../hooks/clientPrefixApi";
import TimeOutToast from "../utils/TimeOutToast";


/**
 * This component represents the the DropDown
 * List to choose the clientPrefix
 * 
 * @author hatem sfar
 *
 */
const ClientPrefixDropDown = () => {


  const clientPrefix = localStorage.getItem("clientPrefix") || null; // if localStorage empty must have the session from server
  const classes = useStyles();
  const [data, setData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selection, setSelection] = useState("");
  const isMenuOpen = Boolean(anchorEl); // Track menu open state



  //eslint-disable-next-line



  useEffect(() => {
    getSessionClientPrefix()
    setTimeout(() => {
      getClientPrefix()
    }, 2000);
    setSelection(clientPrefix)
    //eslint-disable-next-line
  }, [clientPrefix]);


  const handleMenuToggle = (event) => {
    setAnchorEl(isMenuOpen ? null : event.currentTarget);
  };

  const handleClose = (e) => {
    if (e.target.innerText !== selection && e.target.innerText !== "") {
      setSelection(e.target.innerText);
      localStorage.setItem("clientPrefix", e.target.innerText);
      updateSessionClientPrefix(localStorage.getItem("clientPrefix"))
      localStorage.removeItem("zoomLevel");
      localStorage.removeItem("position");
    }
    setSearchText("");
    setAnchorEl(null);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const getClientPrefix = async (req, res) => {

    if (auth([ROLES.OPERATOR])) {
      try {
        clientPrefixApi()
          .then((data) => {
            setData(data ? data : []);
          })
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error("Gateway Time-out: The server took too long to respond.");
          TimeOutToast()
        }
        console.log(err);
      }

    } else {
      return null;
    }

  };

  const getSessionClientPrefix = async (req, res) => {

    if (auth([ROLES.OPERATOR])) {
      try {
        getSessionApi()
          .then((data) => {
            localStorage.setItem("clientPrefix", data ? data : null)
          })

      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error("Gateway Time-out: The server took too long to respond.");
          TimeOutToast()
        }
        console.log(err);
        //return res.status(500).json({ message: "Server Error" });
      }

    } else {
      return null;
      //return res.status(401).json({ message: "You don't have permission" });
    }

  };

  const updateSessionClientPrefix = async (client) => {
    if (auth([ROLES.OPERATOR])) {
      setSessionApi(client)
        .then(() => {
          setSelection(client)
          localStorage.removeItem('zoomLevelLat');
          localStorage.removeItem('zoomLevelLong');
          window.location.replace("/devices")
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return null;
      // res.status(401).json({ message: "You don't have permission" });
    }
  };



  return (
    <>
    
      <div style={{ textAlign: "-webkit-center" }}>
        <Button
          type="button"
          className={classes.DropDownButton}
          onClick={handleMenuToggle} // Toggle menu open/close
          style={{
            height: window.innerWidth < 991 && "40px",
            width: "max-content"
          }}
        >
          {selection}
          <KeyboardArrowDownIcon />
        </Button>
        {renderDashboardMenu()}
      </div>
    </>
  );


  function renderDashboardMenu() {
    const displayOptions =
      data?.map && data.map((item) => {
        if (item.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
        return undefined;
      })
        .filter((item) => item !== undefined);

    function renderOption(value) {
      if (selection === value) {
        return (
          <div className={classes.checkedItem}>
            <CheckIcon />
            {value}
          </div>
        );
      }
      return value;
    }

    const menuItems = [
      <MenuItem
        key="searchBarContainer"
        className={classes.searchBarContainer}
        disableTouchRipple={true}
      >
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            name="searshbar"
            placeholder={`${i18next.t('Search')}...`}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            onChange={handleSearchChange}
            value={searchText}
          />
        </div>
      </MenuItem>,
      <Divider key="searchDivider" />,
      ...displayOptions?.map((item, index) => {
        return (
          <div key={index}>
            <MenuItem onClick={(e) => handleClose(e)}>
              {renderOption(item)}
            </MenuItem>
            <Divider className={classes.menuDivider} />
          </div>
        );
      })
    ];

    if (displayOptions && !displayOptions?.includes(searchText) && searchText !== "") {
      menuItems.push(
        <div key="addOption">
          <MenuItem onClick={() => handleAddOption(searchText)}>
            {`Add "${searchText}"`}
          </MenuItem>
          <Divider className={classes.menuDivider} />
        </div>
      );
    }

    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={!!anchorEl}
        onClose={handleClose}
        className={classes.dashboardSelectMenu}
        anchorReference={null}
        anchorPosition={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {menuItems}
      </Menu>
    );
  }

  function handleAddOption(newOption) {
    const options = [...data];
    options.push(newOption);
    setData(options);
    setSelection(newOption);
    localStorage.setItem("clientPrefix", newOption);
    updateSessionClientPrefix(newOption)
    setSearchText("");
    setAnchorEl(null);
    window.location.reload();
  }
};
export default ClientPrefixDropDown;