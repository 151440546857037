/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import NavBarDesktop from "./NavBarDesktop";
import NavBarMobile from "./NavBarMobile";
import logo from "../../assets/images/logo.png";
import useWindowSize from "../../utils/useWindowSize";
import "../../assets/css/navbar.css";

/**
 * The top navigation bar component.
 * This component displays the logo, title, language dropdown, and menu items based on user roles and screen size.
 *
 * @author hatem sfar
 *
 */
function NavbarTop() {
  const windowSize = useWindowSize();
  const OKIT_URL = "https://agile.okit.de/OKIT.Accounting/";

  return (
    <>
      <Navbar
        className="navbar_top"
        style={{ zIndex: windowSize[0] < 991 ? "999" : "9999" }}
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand>
            <Link to={OKIT_URL}>
              <img
                src={logo}
                width="100%"
                height="30"
                className="d-inline-block align-top"
                alt="okit.ovc logo"
              />
            </Link>
          </Navbar.Brand>
          <font id="vcm_title">
            {windowSize[0] < 500 ? "VCM" : "Vehicle Condition Monitoring"}{" "}
            <button
              onClick={() =>
                window.open("https://www.okit.de/kontakt/", "_blank")
              }
              style={{
                backgroundColor: "#f55500",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "5px",
                fontSize: "16px",
              }}
            >
              Learn more
            </button>
          </font>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            {windowSize[0] > 991 ? <NavBarDesktop /> : <NavBarMobile />}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarTop;
