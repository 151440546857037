/*
 * Project: OKIT.VCM
 * 
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 * 
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useState, createContext } from "react";

// Create Context Object
export const CasUserContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const CasUserContextProvider = ({ children }) => {
  const existingSession = sessionStorage.getItem('user');
  const defaultLoginNameValue = existingSession ? JSON.parse(existingSession).loginName : '';
  const defaultAuthValue = existingSession ? JSON.parse(existingSession).auth : false;
  //eslint-disable-next-line
  const [user, setUser] = useState({loginName: defaultLoginNameValue, auth: defaultAuthValue});
  const login = (loginName) => {
    user.loginName = loginName;
    user.auth = true;
    sessionStorage.setItem('user', JSON.stringify(user));
  };

  const logout = () => {
    user.loginName = '';
    user.auth = false;
    sessionStorage.removeItem('user');
    localStorage.removeItem("zoomLevel")
    localStorage.removeItem("position")
  }

  return (
    <CasUserContext.Provider value={{ user, login, logout }}>
      {children}
    </CasUserContext.Provider>
  );
};
