import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TimeOutToast from "../../utils/TimeOutToast";
import { updateDeviceApi } from "../../hooks/deviceApi";
import { Switch } from "@mui/material";
import { Input } from "antd";
import {
  getMeasurementByNameSpaceApi,
  setMeasurementByNameSpaceApi,
} from "../../hooks/measurementApi";
import useWindowSize from "../../utils/useWindowSize";

export default function Properties({ device }) {
  const [value, setValue] = useState(false);
  const [vehicleIdentificationNumber, setVehicleIdentificationNumber] =
    useState();
  const [deviceName, setDeviceName] = useState();
  const [maxSpeed, setMaxSpeed] = useState();
  const windowSize = useWindowSize();

  useEffect(() => {
    getMeasurement();
    // eslint-disable-next-line
  }, [device]);

  /**
   *
   */
  useEffect(() => {
    device.active !== undefined && setValue(device.active);
  }, [device.active]);

  /**
   * to handle the input in the field
   * @param {*} e
   */
  const handleInputChangeVehicleIdentificationNumber = (e) => {
    const newValue = e.target.value;
    if (vehicleIdentificationNumber !== newValue) {
      setVehicleIdentificationNumber(newValue);
    }
  };
  /**
   * to handle the input in the field
   * @param {*} e
   */
  const handleInputChangeMaxSpeed = (e) => {
    const newValue = e.target.value;
    if (maxSpeed !== newValue) {
      setMaxSpeed(newValue);
    }
  };

  /**
   *
   */
  const updateMeasurement = async () => {
    if (vehicleIdentificationNumber) {
      const namespace = "de.okit.automotive.device.VehicleIdentificationNumber";
      try {
        setMeasurementByNameSpaceApi(
          device.id,
          vehicleIdentificationNumber,
          namespace
        ).then(() => {
          getMeasurement();
        });
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
        console.log(err);
      }
    }
    if (maxSpeed) {
      const namespace = "de.okit.automotive.device.MaxSpeed";
      try {
        setMeasurementByNameSpaceApi(device.id, maxSpeed, namespace).then(
          () => {
            getMeasurement();
          }
        );
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
        console.log(err);
      }
    }
  };

  /**
   * to handle the input in the field
   * @param {*} e
   */
  const handleInputChangeDeviceName = (e) => {
    const newValue = e.target.value;
    if (deviceName !== newValue) {
      setDeviceName(newValue);
    }
  };
  /**
   *
   */
  const updateDevice = async () => {
    console.log("deviceName ", deviceName);
    if (deviceName) {
      device.name = deviceName;
      try {
        // update device active status
        updateDeviceApi(device, value).then((response) => {
          console.log("response: ", response);
        });
      } catch (err) {
        console.log("err: ", err);
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
      }
    }
  };
  /**
   *
   */
  const getMeasurement = async () => {
    const position =
      "de.okit.automotive.device.VehicleIdentificationNumber" +
      "," +
      "de.okit.automotive.device.MaxSpeed";

    getMeasurementByNameSpaceApi(device.id, position)
      .then((response) => {
        response &&
          response?.forEach((info) => {
            if (
              info &&
              info.namespace.endsWith("VehicleIdentificationNumber")
            ) {
              if (info.measurements && info.measurements.length > 0) {
                setVehicleIdentificationNumber(info?.measurements[0]?.content);
              }
            }
            if (info && info.namespace.endsWith("MaxSpeed")) {
              if (info.measurements && info.measurements.length > 0) {
                setMaxSpeed(info?.measurements[0]?.content);
              }
            }
          });
      })
      .catch((error) => {
        if (error.response && error.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
      });
    setDeviceName(device?.name);
  };

  /**
   * to handle the input for boolean type
   */
  const handleInputChangeBool = (e) => {
    setValue(e.target.checked);
    try {
      // update device active status
      updateDeviceApi(device, e.target.checked).then((response) => {
        //console.log("response: ", response)
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
    }
  };

  return (
    <Container
      fluid
      style={{ width: windowSize[0] > 991 ? "50%" : "100%", float: "left" }}
    >
      <h3
        style={{ marginLeft: "15px", marginTop: "10px" }}
        className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
      >
        {i18next.t("Properties")}{" "}
      </h3>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("active")}
          </font>
        </Col>
        <Col>
          <Switch checked={value} onChange={handleInputChangeBool} />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("DeviceName")}
          </font>
        </Col>
        <Col>
          <Input
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
            onChange={handleInputChangeDeviceName}
            onBlur={updateDevice}
            type="text"
            value={deviceName}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("VehicleIdentificationNumber")}
          </font>
        </Col>
        <Col>
          <Input
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
            onChange={handleInputChangeVehicleIdentificationNumber}
            onBlur={updateMeasurement}
            type="text"
            value={vehicleIdentificationNumber}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("maxSpeed")}
          </font>
        </Col>
        <Col>
          <Input
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
            onChange={handleInputChangeMaxSpeed}
            onBlur={updateMeasurement}
            type="text"
            value={maxSpeed}
          />
          {" km/h "}
        </Col>
      </Row>
    </Container>
  );
}
